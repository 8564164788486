// ** Initial State
const initialState = {
  allData: [],
  compData: "",
  spinner: false,
  tokenExp: false,

  tDesignsData: [],
  tspinner: false,
};

const IDTemplateAddReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ID_TEMPLATES_DATA":
      return {
        ...state,
        allData: action.allData,
        compData: action.compData,
        spinner: action.spinner,
      };
    case "GET_ID_TEMPLATES_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "SAVE_TEMPLATE":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "SAVE_TEMPLATE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "SAVE_FIELD_DESIGN":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "SAVE_FIELDDESIGN_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "REGENERATE_TEMPLATE":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "REGENERATE_TEMPLATE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_IDTEMPLATE_DESIGNS":
      return {
        ...state,
        tDesignsData: action.resData,
        tspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_IDTEMPLATE_DESIGNS_LOADING_SPINNER":
      return {
        ...state,
        tspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default IDTemplateAddReducer;
