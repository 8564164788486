// ** Logo
import logo from '@src/assets/images/logo/logo.png'
import rstLogo from '@styles/birdsong/images/MobileApp/rst/rstechicon.png'
import sambLogo from '@styles/birdsong/images/MobileApp/samb/sreeambaalsicon.png'
import gvLogo from '@styles/birdsong/images/MobileApp/gvacademy/gvanewicon.png'
import svLogo from '@styles/birdsong/images/MobileApp/sv/svlogo.jpg'
import atkLogo from '@styles/birdsong/images/MobileApp/atkinson/atklogo.png'
import svidLogo from '@styles/birdsong/images/MobileApp/svid/svidlogo.png'
import knarayanaLogo from '@styles/birdsong/images/MobileApp/knarayana/knarayanalogo.png'
import vhsLogo from '@styles/birdsong/images/MobileApp/vhs/vhslogo.png'
import cubsLogo from '@styles/birdsong/images/MobileApp/cubs/cubsicon.png'
import asssLogo from '@styles/birdsong/images/MobileApp/asss/asssicon.png'
import shastraLogo from '@styles/birdsong/images/MobileApp/shastra/stricon.png'
import cpsLogo from '@styles/birdsong/images/MobileApp/cps/cpslogo.png'
import ssvvLogo from '@styles/birdsong/images/MobileApp/ssvv/ssvvlogo.png'
import gvtLogo from '@styles/birdsong/images/MobileApp/gvt/gvanewicon.png'
import mscLogo from '@styles/birdsong/images/MobileApp/montfort/mscicon.png'
import depaulLogo from '@styles/birdsong/images/MobileApp/depaul/dePaulIcon.png'
import oakLogo from '@styles/birdsong/images/MobileApp/oakValley/oakIcon.png'
import atkSrSecLogo from '@styles/birdsong/images/MobileApp/atkSrSec/atkIcon.png'
import gsrLogo from '@styles/birdsong/images/MobileApp/gsr/gsricon.png'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vh-100 d-flex justify-content-center align-items-center position-relative'>
      <img className='fallback-logo' 
        src={window.location.pathname === '/brdsng-login/gvacademy' ? 
          gvLogo : window.location.pathname === '/brdsng-login/sv' ? svLogo 
          : window.location.pathname === '/brdsng-login/rstech' ? rstLogo : 
          window.location.pathname === '/brdsng-login/samb' ? sambLogo :
          window.location.pathname === '/gvt-login/atkinson' ? atkLogo : 
          window.location.pathname === '/brdsng-login/svid' ? svidLogo : 
          window.location.pathname === '/brdsng-login/knarayana' ? knarayanaLogo : 
          window.location.pathname === '/brdsng-login/vhs' ? vhsLogo : 
          window.location.pathname === '/brdsng-login/cubs' ? cubsLogo : 
          window.location.pathname === '/brdsng-login/asss' ? asssLogo : 
          window.location.pathname === '/brdsng-login/shastra' ? shastraLogo : 
          window.location.pathname === '/brdsng-login/cps' ? cpsLogo : 
          window.location.pathname === '/brdsng-login/ssvv' ? ssvvLogo : 
          window.location.pathname === '/brdsng-login/msc' ? mscLogo :
          window.location.pathname === '/gvt-login/depaul' ? depaulLogo :
          window.location.pathname === '/gvt-login/oakValley' ? oakLogo :
          window.location.pathname === '/gvt-login/atkSrSec' ? atkSrSecLogo :
          window.location.pathname === '/gvt-login/gsredu' ? gsrLogo : 
          window.location.pathname === '/gvt-login' ? gvtLogo : logo} alt='logo' width="60px" height="60px" />
      {/* <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div> */}
      <div class="loader-1">
        <div className='loader'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
