import { getData, getResultData } from "../action";
import { DefaultConfig } from "../../../../../../settings/config";
const gvFinGrp = DefaultConfig().finGrp === "GAITVIEWGR"

// ** Initial State
const initialState = {
  examData: [],
  resultData: [],
  columnData: [],
  espinner: false,
  sspinner: false,
  tokenExp: false,
  loading: false,
  allData: [],
  csoData: {},
  data: [],
  eMarks: '',
  total: 1,
  params: {},
  rspinner: true,
  stuRef: null,
  subRef: null,
  indexId: null,
  cosId: null,
  groupRef: null,
  ltype: "",
};
const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      // resp.rollNo = Number(resp.rollNo);
    });
    return resData;
  } else {
    return [];
  }
};
const getColumns = (data) => {
  const Branches = DefaultConfig().Branches;
  var columns = [];
  columns.push(
    {
      name: "S.No",
      minWidth: "0px",
      selector: "serial",
      sortable: true,
      showhide: true,
      cell: (row) => row.serial,
    },
    {
      name: "Branch",
      minWidth: "0px",
      selector: "branchName",
      sortable: true,
      showhide: true,
      cell: (row) => row.branchName,
    },
    {
      name: "Name",
      minWidth: "0px",
      selector: "name",
      sortable: true,
      showhide: true,
      cell: (row) => row.name,
    },
    {
      name: "Adm No.",
      minWidth: "0px",
      selector: "admNo",
      sortable: true,
      showhide: gvFinGrp ? false : true,
      cell: (row) => row.admNo,
    },
    {
      name: "Roll No",
      minWidth: "0px",
      selector: "rollNo",
      sortable: true,
      showhide: gvFinGrp ? false : true,
      cell: (row) => row.rollNo,
    }
  );
  const subjects = data.subjects;
  subjects.forEach((element) => {
    columns.push({
      name: element.subRef === -1 ? "Elective-1" : element.subRef === -2 ? "Elective-2" : element.subRef === -3 ? "Elective-3" : element.name,
      minWidth: "0px",
      selector: element.subRef,
      sortable: true,
      showhide: true,
      cell: (row) => row[element.subRef],
    });
  });
  columns.push(
    {
      name: "Total",
      minWidth: "0px",
      selector: "total",
      sortable: true,
      showhide: true,
      cell: (row) => row.total,
    },
    {
      name: "Per. (%)",
      minWidth: "0px",
      selector: "percent",
      sortable: true,
      showhide: true,
      cell: (row) => row.percent,
    },
    {
      name: "Grade",
      minWidth: "0px",
      selector: "grade",
      sortable: true,
      showhide: true,
      cell: (row) => row.grade,
    },
    {
      name: "Village/Area",
      minWidth: "0px",
      selector: "village",
      sortable: true,
      showhide: false,
      cell: (row) => row.village,
    },
    {
      name: "Remarks",
      minWidth: "0px",
      selector: "remarks",
      sortable: true,
      showhide: false,
      cell: (row) => row.remarks,
    }
  );
  return columns;
};

const getAllData = (data, percSort) => {
  const resultEntries = data.resultEntries;
  const subjects = data.subjects;
  const Grades = DefaultConfig().Grades;
  const sorting = DefaultConfig().sorter;
  const incmarks = data.includeAssMarks;
  const Branches = DefaultConfig().Branches;
  resultEntries.forEach((item, index) => {
    const examMarks = item.examMarks;
    const assMarks = item.assMarks;

    let totalMarks = 0;
    let summarks = 0;
    let percent = 0;
    let grade = "";
    subjects.forEach((sub) => {
      totalMarks += sub.max;
      if (incmarks) {
        const subA = assMarks[sub.subRef];
        const checkassVal = !isNaN(subA);
        if (checkassVal) {
          summarks += Number(subA);
        }
      }
      const subE = examMarks[sub.subRef];
      const checkexmVal = !isNaN(subE);
      if (checkexmVal) {
        summarks += Number(subE);
      }
      // console.log(subA, subE, checkassVal, checkexmVal)
    });
    percent = (summarks / totalMarks) * 100;
    percent = Number.parseFloat(percent).toFixed(2);

    for (let i = 0; i < Grades.length; i++) {
      if (
        Number(percent) >= Grades[i].minPercent &&
        Grades[i].maxPercent >= Number(percent)
      ) {
        grade = Grades[i].grade;
        break;
      }
    }
    item.branchName = Branches.find((find) => find.value === item.branchId)
      ? Branches.find((find) => find.value === item.branchId).branchName
      : "";
    item.summarks = Number(summarks);
    item.total = summarks > 0 ? summarks + " / " + totalMarks : "";
    item.percent = summarks > 0 ? Number(percent) : "";
    item.grade = summarks > 0 ? grade : "";
  });
  // const sortData = sorting("percent", "desc", resultEntries);
  // data["resultEntries"] = sortData;
  // return data;
  if(percSort === 'perc'){
    const sortData = resultEntries.sort((a, b) => b.percent - a.percent);
  data["resultEntries"] = sortData;
  }
  return data;
};

const ResultEntry = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EXAM_DATA":
      return {
        ...state,
        examData: action.data,
        espinner: action.spinner,
      };
    case "GET_RES_ENT_DATA":
      return {
        ...state,
        columnData: getColumns(action.data),
        resultData: getAllData(action.data, action.percSort),
        sspinner: action.spinner,
      };
    case "RES_ENT_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.espinner,
        sspinner: action.sspinner,
        tokenExp: action.tokenExp,
        loading: action.loading,
      };
    case "GET_RES_ENT_ALL_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_RES_FIL_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
      };
    case "GET_SORT_RES_FIL_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
      };
    case "RES_SAVE_LOADING_SPINNER":
      return {
        ...state,
        rspinner: action.rspinner,
        tokenExp: action.tokenExp,
        subRef: action.subRef,
        indexId: action.indexId, 
        cosId: action.cosId,
        groupRef: action.group,
        stuRef: action.stuRef,
        ltype: action.ltype,
      };
    case "GET_RES_ENT_SAVE_DATA":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        rspinner: action.rspinner,
        csoData: action.csoData,
        eMarks: action.eMarks
      };
    case "GET_RES_ENT_UPDATE_DATA":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_INIT":
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return { ...state };
  }
};
export default ResultEntry;
