// ** Initial State
const initialState = {
  selectedData: null,
  allData: [],
  spinner: false,
  tokenExp: false,
};

const TransferCertificate = (state = initialState, action) => {
     switch (action.type) {
        case "GET_DATA":
          return {
          ...state,
          allData: action.allData,
          spinner: action.spinner,
          };
        case "UPDATE_TC":
          return {
            ...state,
            allData: action.allData,
            spinner: action.spinner,
          };
        case "GET_TC":
          return {
             ...state,
            selectedData: action.selectedData,
            actionTitle: action.actionTitle,
          };
        case "TC_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
        case "REMOVE_STU":
          return {
            ...state,
            expData: null,
          };
    default:
      return { ...state };
  }
};
export default TransferCertificate;
