import * as yup from "yup";
import { Fragment, useState, useEffect } from "react";
import Select from "react-select";
import classnames from "classnames";
import { useForm, Controller } from "react-hook-form";
import { selectThemeColors, isObjEmpty } from "@utils";
import {
  Label,
  FormGroup,
  Row,
  Col,
  Button,
  Form,
  Input,
  Card,
  CardBody,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { DefaultConfig } from "../../../../../settings/config";
import { getAllData } from "../store/action";
import { useDispatch } from "react-redux";
import { getDirRule } from "postcss-rtl/lib/rules";
import { ErrorMessage } from "@hookform/error-message";
import SiblingTable from "./Table";

const siblingForm = ({}) => {
  // ** Store Vars
  const dispatch = useDispatch();

  // ** States
  const [compId, setcompId] = useState("");
  const [getId, setGetId] = useState();
  const selectedBranches = DefaultConfig().Branches.map((cus) => ({
    value: cus.value,
    label: cus.branchName,
  }));

  // console.log(selectedBranches, "sel");
  // console.log(selectedBranches.length);
  // console.log(getId);

  useEffect(() => {
    if (selectedBranches.length === 1) {
      setGetId(selectedBranches[0].value);
    }
  }, [selectedBranches]);

  // ** updates
  const ValidSchema = yup.object().shape({
    [`compId`]: yup.number().required("Company ID is a required field"),
  });

  const { register, errors, handleSubmit, trigger, control, formState } =
    useForm({
      resolver: yupResolver(ValidSchema),
      mode: "onChange",
    });
  const { isDirty, isValid } = formState;

  // const compIdChange = (value) => {
  //   setcompId(value);
  // };
  // const compIdSubmit = () => {
  //   dispatch(getAllData(compId));
  //   setGetId(compId);
  // };
  // ** Get data on mount
  // useEffect(() => {
  //   if (getId) {
  //     dispatch(getAllData(getId));
  //   }
  // }, [getId]);

  return (
    <Fragment>
      {selectedBranches.length !== 1 && (
        <div>
          <Col>
            <Card>
              <CardBody className="">
                <Form>
                  <Row>
                    <Col sm="12" md="4" lg="4" xl="4" />
                    <Col sm="12" md="4" lg="4" xl="4">
                      <FormGroup>
                        <Label for="compId">Branch</Label>
                        <Controller
                          render={({ onChange, value, ref }) => (
                            <Select
                              inputRef={ref}
                              classNamePrefix="select"
                              placeholder="Select"
                              options={selectedBranches}
                              value={selectedBranches.find(
                                (c) => c.value === value
                              )}
                              onChange={(val) => {
                                onChange(val ? val.value : ""),
                                  setGetId(val ? val.value : "");
                              }}
                              className={classnames("react-select")}
                              isClearable
                            />
                          )}
                          control={control}
                          name="compId"
                          theme={selectThemeColors}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="4" lg="4" xl="4" />
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </div>
      )}

      {getId && (
        <Card>
          <CardBody>
            <SiblingTable getId={getId} />
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};

export default siblingForm;
