import { DefaultConfig } from "../../../../../settings/config";
// ** Initial State
const initialState = {
  allData: [],
  ciList: [],
  data: [],
  total: 1,
  params: {},
  tokenExp: false,

  stockValueData: [],
  totalStockValueSumData: [],
  repTypeName: '',
  totSaleValue: '',
  stockValue: '',
  svSpinner: false,
  spinner: false,

  itemAllData: [],
  totalItemSumData: [],
  itemRepTypeName: '',
  itemSpinner: false,
  itemData: [],
  itemTotal: 1,
  itemParams: {},

  itemDtlAllData: [],
  totalItemWiseDtlSumData: [],
  itemWiseDtlData: [],
  itemWiseDtlTotal: 1,
  itemWiseDtlParams: {},
  itemWiseDtlSpinner: false,

  saleValue: '',
  slvSpinner: false,

  ppValue: '',
  ppSpinner: false,

  piValue: '',
  piSpinner: false,

  pdValue: '',
  pdSpinner: false,

  pdbData: [],
  pdbSpinner: false,

  saleListTotData: [],
  slRepTypeName: "",
  slTotSpinner: false,

  saleListData: [],
  totalSaleListSumData: [],
  slData: [],
  slTotal: 1,
  slParams: {},
  slSpinner: false,

  itemListData: [],
  totalItemListSumData: [],
  ilData: [],
  ilTotal: 1,
  ilParams: {},
  ilSpinner: false,

  comboListData: [],
  totalComboListSumData: [],
  clData: [],
  clTotal: 1,
  clParams: {},
  clSpinner: false,

  totalSVdata: [],

  lsallData: [],
  lsdata: [],
  lstotal: 1,
  lsparams: {},
  lsSpinner: false,

  lowStockData: [],
  rapidSaleData: [],
  rsSpinner: false,

  sivData: {},
  sivSpinner: false,

  branchItemsCount: {},
  icSpinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const initialItemLS = (resData) => {
  const branchOptions = DefaultConfig().Branches
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      resp.companyName = branchOptions.find(find => find.value === resp.compId) ? branchOptions.find(find => find.value === resp.compId).branchName : ''
      resp.itemRef = resp.itemRef;
      resp.itemName = resp.itemName;
      resp.itemLowStockAlert = resp.itemLowStockAlert;
      resp.currentStock = resp.currentStock;
    });
    return resData;
  } else {
    return [];
  }
}

const InventoryDashboard = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STOCK_VALUE_DATA":
      return {
        ...state,
        stockValue: action.stockValue,
        svSpinner: action.spinner,
      };
    case "GET_STOCK_VALUE_LOADING_SPINNER":
      return {
        ...state,
        svSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_STOCK_VALUE_REPORT_DATA":
      return {
        ...state,
        stockValueData: initialItem(action.stockValueData),
        totalStockValueSumData: action.totalStockValueSumData,
        data: action.data,
        repTypeName: action.repTypeName,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_STOCK_VALUE_REPORT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STOCK_VALUE_FIL_DATA":
      return {
        ...state,
        data: action.data,
        totalStockValueSumData: action.totalStockValueSumData,
        totalSVdata: action.totalSVdata,
        total: action.totalPages,
        params: action.params,
      };

    case "GET_STOCK_VALUE_ITEM_WISE_REPORT_DATA":
      return {
        ...state,
        itemAllData: initialItem(action.stockValueData),
        totalItemSumData: action.totalStockValueSumData,
        itemData: action.data,
        itemRepTypeName: action.repTypeName,
        itemTotal: action.totalPages,
        itemParams: action.params,
        itemSpinner: action.spinner,
      };
    case "GET_STOCK_VALUE_ITEM_REPORT_LOADING_SPINNER":
      return {
        ...state,
        itemSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STOCK_VALUE_ITEM_WISE_FIL_DATA":
      return {
        ...state,
        itemData: action.data,
        totalItemSumData: action.totalStockValueSumData,
        itemTotal: action.totalPages,
        itemParams: action.params,
      };

    case "GET_ITEM_WISE_DTL_REPORT_DATA":
      return {
        ...state,
        itemDtlAllData: initialItem(action.itemWiseDtlData),
        totalItemWiseDtlSumData: action.totalItemWiseDtlSumData,
        itemWiseDtlData: action.data,
        itemWiseDtlTotal: action.totalPages,
        itemWiseDtlParams: action.params,
        itemWiseDtlSpinner: action.spinner,
      };
    case "GET_ITEM_WISE_DTL_REPORT_LOADING_SPINNER":
      return {
        ...state,
        itemWiseDtlSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ITEM_WISE_DTL_FIL_DATA":
      return {
        ...state,
        itemWiseDtlData: action.data,
        totalItemWiseDtlSumData: action.totalItemWiseDtlSumData,
        itemWiseDtlTotal: action.totalPages,
        itemWiseDtlParams: action.params,
      };

    case "GET_SALE_VALUE_DATA":
      return {
        ...state,
        saleValue: action.saleValue,
        slvSpinner: action.spinner,
      };
    case "GET_SALE_VALUE_LOADING_SPINNER":
      return {
        ...state,
        slvSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_SALE_LIST_DATA":
      return {
        ...state,
        saleListTotData: action.saleList,
        slRepTypeName: action.repTypeName,
        totSaleValue: action.totSaleValue,
        slTotSpinner: action.spinner,
      };
    case "GET_SALE_LIST_LOADING_SPINNER":
      return {
        ...state,
        slTotSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_SALE_LIST_REPORT_DATA":
      return {
        ...state,
        saleListData: action.saleListData,
        totalSaleListSumData: action.totalSaleListSumData,
        slData: action.data,
        slTotal: action.totalPages,
        slParams: action.params,
        slSpinner: action.spinner,
      };
    case "GET_SALE_LIST_REPORT_LOADING_SPINNER":
      return {
        ...state,
        slSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_SALE_LIST_REPORT_FIL_DATA":
      return {
        ...state,
        totalSaleListSumData: action.totalSaleListSumData,
        slData: action.slData,
        slTotal: action.totalPages,
        slParams: action.params,
        slSpinner: action.spinner,
      };

    case "GET_ITEM_LIST_REPORT_DATA":
      return {
        ...state,
        itemListData: action.itemListData,
        totalItemListSumData: action.totalItemListSumData,
        ilData: action.data,
        ilTotal: action.totalPages,
        ilParams: action.params,
        ilSpinner: action.spinner,
      };
    case "GET_ITEM_LIST_REPORT_LOADING_SPINNER":
      return {
        ...state,
        ilSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ITEM_LIST_REPORT_FIL_DATA":
      return {
        ...state,
        totalItemListSumData: action.totalItemListSumData,
        ilData: action.data,
        ilTotal: action.totalPages,
        ilParams: action.params,
        ilSpinner: action.spinner,
      };

    case "GET_COMBO_LIST_REPORT_DATA":
      return {
        ...state,
        comboListData: action.comboListData,
        totalComboListSumData: action.totalComboListSumData,
        clData: action.data,
        clTotal: action.totalPages,
        clParams: action.params,
        clSpinner: action.spinner,
      };
    case "GET_COMBO_LIST_REPORT_FIL_DATA":
      return {
        ...state,
        totalComboListSumData: action.totalComboListSumData,
        clData: action.data,
        clTotal: action.totalPages,
        clParams: action.params,
        clSpinner: action.spinner,
      };
    case "GET_LOW_STOCK_DATA":
      return {
        ...state,
        lowStockData: action.allData,
        lsallData: initialItemLS(action.allData),
        lsdata: action.data,
        lstotal: action.totalPages,
        lsparams: action.params,
        lsSpinner: action.spinner,
      };
    case "GET_LOW_STOCK_LOADING_SPINNER":
      return {
        ...state,
        lsSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_RAPID_SALE_LOADING_SPINNER":
      return {
        ...state,
        rsSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_RAPID_SALE_DATA":
      return {
        ...state,
        rapidSaleData: action.allData,
        rsSpinner: action.spinner,
      };
    case "GET_LOW_STOCK_FIL_DATA":
      return {
        ...state,
        lsdata: action.data,
        lstotal: action.totalPages,
        lsparams: action.params
      };

    case "GET_PENDING_PURCHASE_DATA":
      return {
        ...state,
        ppValue: action.ppValue,
        ppSpinner: action.spinner,
      };
    case "GET_PENDING_PURCHASE_LOADING_SPINNER":
      return {
        ...state,
        ppSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_PENDING_INDENTS_DATA":
      return {
        ...state,
        piValue: action.piValue,
        piSpinner: action.spinner,
      };
    case "GET_PENDING_INDENTS_LOADING_SPINNER":
      return {
        ...state,
        piSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    
      case "GET_PENDING_DISTRIBUTES_DATA":
        return {
          ...state,
          pdValue: action.pdValue,
          pdSpinner: action.spinner,
        };
      case "GET_PENDING_DISTRIBUTES_LOADING_SPINNER":
        return {
          ...state,
          pdSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_PENDING_DISTRIBUTIONS_DATA":
        return {
          ...state,
          pdbData: action.resData,
          pdbSpinner: action.spinner,
        };
      case "GET_PENDING_DISTRIBUTIONS_LOADING_SPINNER":
        return {
          ...state,
          pdbSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "SAVE_ITEM_VERIFICATION":
        return {
          ...state,
          itemAllData: initialItem(action.stockValueData),
          totalItemSumData: action.totalStockValueSumData,
          sivData: action.resData,
          sivSpinner: action.spinner,
        };
      case "SAVE_ITEM_VERIFICATION_LOADING_SPINNER":
        return {
          ...state,
          sivSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_BRANCH_ITEMS_COUNT_DATA":
        return {
          ...state,
          branchItemsCount: action.resData,
          icSpinner: action.spinner,
        };
      case "GET_BRNCHWISE_ITEMS_COUNT_LOADING_SPINNER":
        return {
          ...state,
          icSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
    default:
      return { ...state };
  }
};
export default InventoryDashboard;
