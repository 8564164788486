import { DefaultConfig } from "../../../../../../settings/config";
import { paginateArray } from "../../../../../../@fake-db/utils";
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,

  eallData: [],
  edata: [],
  etotal: 1,

  ballData: [],
  bdata: [],
  btotal: 1,

  cballData: [],
  cbdata: [],
  cbtotal: 1,
  cbColumns: [],

  crallData: [],
  crdata: [],
  crtotal: 1,
  crColumns: [],

  feeHeadData: [],
  fhSpinner: false,
  feeHeadMap: [],
  dayWiseList: [],
  catgWiseList: [],
  bankWiseMap: [],
  payModeWiseList: [],
  feeHeadWiseTotMap: {},

  dynamicParams: []
};

const initialItem = (resData) => {
  const branchOptions = DefaultConfig().Branches;
  if (resData) {
    resData.forEach((element, index) => {
      element.serial = index + 1
      element.companyName = branchOptions.find(find => find.value === element.branchId) ? branchOptions.find(find => find.value === element.branchId).branchName : ''
    });
    return resData;
  } else {
    return [];
  }
};

const initcbColumns = (resData, value, reqData) => {
  if (resData) {
    const feeHeadMap = resData.feeHeadMap
    const arrayOfObj = []
    if (value === 'y') {
      arrayOfObj.push(
        {
          name: "S.NO",
          minWidth: "0px",
          maxWidth: "50px",
          selector: "serial",
          sortable: true,
          showhide: true,
          cell: (row) => row.serial,
        },
        {
          name: 'Class or Branch',
          minWidth: "0px",
          selector: "clsbrnch",
          sortable: true,
          showhide: true,
          cell: (row) => row['clsbrnch'],
        },
      )
      if (reqData.headerType === 1) {
        for (let key in feeHeadMap) {
          arrayOfObj.push(
            {
              name: feeHeadMap[key],
              minWidth: "0px",
              selector: key,
              sortable: true,
              showhide: true,
              cell: (row) => row[key],
            }
          )
        }
      } else {
        function getDates(startDate, endDate) {
          const dates = []
          let currentDate = startDate
          const addDays = function (days) {
            const date = new Date(this.valueOf())
            date.setDate(date.getDate() + days)
            return date
          }
          while (currentDate <= endDate) {
            dates.push(new Date(currentDate).getFullYear() + '-' + ('0' + (new Date(currentDate).getMonth() + 1)).slice(-2) + '-' + ('0' + new Date(currentDate).getDate()).slice(-2))
            currentDate = addDays.call(currentDate, 1)
          }
          return dates
        }

        // Usage
        const dates = getDates(new Date(reqData.fromDate), new Date(reqData.toDate))
        dates.forEach((date) => {
          arrayOfObj.push(
            {
              name: date,
              minWidth: "0px",
              selector: date,
              sortable: true,
              showhide: true,
              cell: (row) => row[date],
            }
          )
        })
      }
      arrayOfObj.push(
        {
          name: 'Cash Total',
          minWidth: "0px",
          selector: "cashTotal",
          sortable: true,
          showhide: true,
          cell: (row) => row['cashTotal'],
        },
        {
        name: 'Bank Total',
        minWidth: "0px",
        selector: "bankTotal",
        sortable: true,
        showhide: true,
        cell: (row) => row['bankTotal'],
      },
       {
        name: 'Total',
        minWidth: "0px",
        selector: "rowTotal",
        sortable: true,
        showhide: true,
        cell: (row) => row['rowTotal'],
      }
      )
    }

    if (value === 'n') {
      for (let key in feeHeadMap) {
        arrayOfObj.push(
          {
            name: feeHeadMap[key],
            minWidth: "0px",
            selector: key,
            sortable: true,
            showhide: true,
            cell: (row) => row[key],
          }
        )
      }
      arrayOfObj.push(
        {
          name: "Cash Credit",
          minWidth: "0px",
          selector: "cashCredit",
          sortable: true,
          showhide: true,
          cell: (row) => row.cashCredit,
        },
        {
          name: 'Bank Credit',
          minWidth: "0px",
          selector: "bankCredit",
          sortable: true,
          showhide: true,
          cell: (row) => row['bankCredit'],
        },
        {
          name: 'Pending Amount',
          minWidth: "0px",
          selector: "pending",
          sortable: true,
          showhide: true,
          cell: (row) => row['pending'],
        }
      )
    }
    return arrayOfObj
  } else {
    return []
  }
}

const initcbData = (catgWiseList, resData, reqData) => {
  const feeHeadMap = resData.feeHeadMap
  let arrayOfObj = []
  if (reqData.headerType === 1) {
    for (let key in feeHeadMap) {
      arrayOfObj.push(
        { key: key, value: feeHeadMap[key] }
      )
    }
  } else {
    function getDates(startDate, endDate) {
      const dates = []
      let currentDate = startDate
      const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
      }
      while (currentDate <= endDate) {
        dates.push(new Date(currentDate).getFullYear() + '-' + ('0' + (new Date(currentDate).getMonth() + 1)).slice(-2) + '-' + ('0' + new Date(currentDate).getDate()).slice(-2))
        currentDate = addDays.call(currentDate, 1)
      }
      return dates
    }

    // Usage
    const dates = getDates(new Date(reqData.fromDate), new Date(reqData.toDate))
    dates.forEach((date) => {
      arrayOfObj.push(
        { key: date, value: date }
      )
    })
  }

  const catgWiseListData = []
  catgWiseList.forEach((item, index) => {
    let catgWiseObj = {}
    catgWiseObj['serial'] = index + 1
    catgWiseObj['reference'] = item.reference
    catgWiseObj['cashTotal'] = item.cashTotal
    catgWiseObj['bankTotal'] = item.bankTotal
    catgWiseObj['rowTotal'] = item.rowTotal
    arrayOfObj.forEach((itemKey) => {
      catgWiseObj[itemKey.key] = {}
      catgWiseObj[itemKey.key]['cashCredit'] = ''
      catgWiseObj[itemKey.key]['bankCredit'] = ''
      catgWiseObj[itemKey.key]['pending'] = ''

      let catgWiseResponse = item.catgWiseResponse
      let findCatName = null
      if (reqData.headerType === 1) {
        findCatName = catgWiseResponse.find(find => find.catg === itemKey.key)
      } else {
        findCatName = catgWiseResponse.find(find => (new Date(find.catg).getFullYear() + '-' + ('0' + (new Date(find.catg).getMonth() + 1)).slice(-2) + '-' + ('0' + new Date(find.catg).getDate()).slice(-2)) === itemKey.key)
      }
      catgWiseObj[itemKey.key]['cashCredit'] = findCatName ? findCatName.cashCredit : ''
      catgWiseObj[itemKey.key]['bankCredit'] = findCatName ? findCatName.bankCredit : ''
      catgWiseObj[itemKey.key]['pending'] = findCatName ? findCatName.pending : ''
    })
    catgWiseListData.push(catgWiseObj)
  })
  return catgWiseListData
}

const initcrData = (catgWiseList, resData) => {
  const feeHeadMap = resData.feeHeadMap
  let arrayOfObj = []
  for (let key in feeHeadMap) {
    arrayOfObj.push(
      { key: key, value: feeHeadMap[key] }
    )
  }

  catgWiseList.forEach((item, index) => {
    item['serial'] = index + 1
    arrayOfObj.forEach((itemKey) => {
      item[itemKey.key] = {}
      const catgWiseList = item.catgWiseList
      const findCatName = catgWiseList.find(find => find.catg === itemKey.key)
      item[itemKey.key]['amount'] = findCatName ? findCatName.amount : ''
    })
  })

  return catgWiseList
}

const payModeWisePayments = (catgWiseList, paymode, resData) => {
  const feeHeadMap = resData.feeHeadMap
  let arrayOfObj = []
  for (let key in feeHeadMap) {
    arrayOfObj.push(
      { key: key, value: feeHeadMap[key] }
    )
  }
  paymode.forEach((mode) => {
    const findData = catgWiseList.find(find => find.mode === mode.payMode)
    if (findData) {
      findData[mode.payMode + 'allData'].forEach((item, index) => {
        item['serial'] = index + 1
        arrayOfObj.forEach((itemKey) => {
          item[itemKey.key] = {}
          const catgWiseList = item.catgWiseList
          const findCatName = catgWiseList.find(find => find.catg === itemKey.key)
          item[itemKey.key]['amount'] = findCatName ? findCatName.amount : ''
        })
      })
    }
  })

  return catgWiseList
}

const initiFeeHeads = (resData) => {
  if (resData) {
    const feeHeadMap = resData.feeHeadMap
    const arrayOfObj = []
    for (let key in feeHeadMap) {
      arrayOfObj.push(
        { key: key, value: feeHeadMap[key] }
      )
    }
    return arrayOfObj
  } else {
    return []
  }
}

const DailyCollection = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_REPORT_DATA":
      return {
        ...state,
        eallData: initialItem(action.eallData),
        edata: action.edata,
        etotal: action.etotalPages,

        ballData: action.ballData,
        bdata: action.bdata,
        btotal: action.btotalPages,

        cballData: initcbData(action.cballData, action.allData, action.reqData),
        cbdata: paginateArray(initcbData(action.cballData, action.allData, action.reqData), action.params.perPage, action.params.page),
        cbtotal: action.cbtotalPages,
        cbColumns: initcbColumns(action.allData, 'y', action.reqData),

        feeHeadMap: initiFeeHeads(action.allData),

        crallData: initcrData(action.crallData, action.allData),
        crdata: action.crdata,
        crtotal: action.crtotalPages,
        crColumns: initcbColumns(action.allData, 'n', action.reqData),

        dynamicParams: payModeWisePayments(action.dynamicParams, action.allData.payModeWiseList, action.allData),

        payModeWiseList: action.allData.payModeWiseList,
        feeHeadWiseTotMap: action.allData.feeHeadWiseTotMap,

        params: action.params,
        spinner: action.spinner,
      };
    case "GET_PENDING_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        totalPenSumData: action.totalPenSumData,
        params: action.params,
      };
    case "GET_EMP_WISE_DATA":
      return {
        ...state,
        edata: action.data,
        etotal: action.totalPages,
        params: action.params,
      };
    case "GET_BRCH_WISE_DATA":
      return {
        ...state,
        bdata: action.data,
        btotal: action.totalPages,
        params: action.params,
      };
    case "GET_CRB_WISE_DATA":
      return {
        ...state,
        cbdata: action.data,
        cbtotal: action.totalPages,
        params: action.params,
      };
    case "GET_CRA_WISE_DATA":
      return {
        ...state,
        crdata: action.data,
        crtotal: action.totalPages,
        params: action.params,
      };
    case "GET_PM_WISE_DATA":
      return {
        ...state,
        dynamicParams: action.dynamicParams,
        params: action.params,
      };
    case "REPORT_PAYMENT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "FEE_BY_CLS_LOADING_SPINNER":
      return {
        ...state,
        fhSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_CLS_FEE_HEAD_DATA":
      return {
        ...state,
        feeHeadData: action.data,
        fhSpinner: action.spinner,
      };
    default:
      return { ...state };
  }
};
export default DailyCollection;
