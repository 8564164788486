import { DefaultConfig } from "../../../../../settings/config";
const DateFormatMonth = DefaultConfig().DateFormatMonth

// ** Initial State
const initialState = {
  allData: [],

  vechicleRemindData: [],
  stallData: [],
  stdata: [],
  sttotal: 1,
  stparams: {},
  vrdSpinner: false,
  tokenExp: false,

  vechicleHistoryData: [],
  shallData: [],
  shdata: [],
  shtotal: 1,
  shparams: {},
  vhSpinner: false,

  vchGpsData: [],
  gpsSpinner: false,

  vchGpsHistory: [],
  hsitorySpinner: false,

  selectedReprint: null,
  actionTitle: "",
  spinner: false,

  vmallData: [],
  vmdata: [],
  vmtotal: 1,
  vmparams: {},
  vmSpinner: false,

  vpallData: [],
  vpdata: [],
  vptotal: 1,
  vpparams: {},
  vpSpinner: false,

  vehBranchData: [],
  vbSpinner: false
}

const initialItem = (resData) => {
  const branchOptions = DefaultConfig().Branches;
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      resp.companyName = branchOptions.find(find => find.value === resp.compId) ? branchOptions.find(find => find.value === resp.compId).branchName : ''
      resp.renewalDate = resp.renewalDate ? DateFormatMonth(resp.renewalDate) : ""
      resp.validityDate = resp.validityDate ? DateFormatMonth(resp.validityDate) : ""

      var fromDt = new Date();
      var toDt = new Date(resp.validityDate);
      var time_difference = toDt.getTime() - fromDt.getTime();
      const day_diff = Math.round(Math.abs(time_difference / (1000 * 60 * 60 * 24)));
      resp["dayDiff"] = day_diff + 1
    });
    return resData;
  } else {
    return [];
  }
};

const initialHisItem = (resData) => {
  const branchOptions = DefaultConfig().Branches;
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      resp.companyName = branchOptions.find(find => find.value === resp.compId) ? branchOptions.find(find => find.value === resp.compId).branchName : ''
    });
    return resData;
  } else {
    return [];
  }
};

const initialMilItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      resp.readingDate = resp.readingDate ? DateFormatMonth(resp.readingDate) : ""
    });
    return resData;
  } else {
    return [];
  }
};

const getData = (resData) => {
  const branchOptions = DefaultConfig().Branches;
  if (resData) {
    const branchData = []
    resData.forEach((resp) => {
      const vehData = []
      if (resp.branchWiseResponse) {
        resp.branchWiseResponse.forEach((resps) => {
          vehData.push({ value: resps.deviceId, label: resps.regNo })
        });
      }
      const name = branchOptions.find(find => find.value === resp.compId) ? branchOptions.find(find => find.value === resp.compId).branchName : ''
      branchData.push({ value: resp.compId, label: name, vehData: vehData })
    });
    return branchData;
  } else {
    return [];
  }
}

const VehicleReminders = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_VEHIREMIND_DATA":
      return {
        ...state,
        stallData: initialItem(action.allData),
        stdata: action.data,
        sttotal: action.totalPages,
        stparams: action.params,
        vrdSpinner: action.spinner
      }
    case "GET_VEH_REM_DATA_FILTER":
      return {
        ...state,
        stdata: action.data,
        sttotal: action.totalPages,
        stparams: action.params,
      };
    case "VEHIREMIND_LOADING_SPINNER":
      return {
        ...state,
        vrdSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_ALL_VEHIHIS_DATA":
      return {
        ...state,
        shallData: initialHisItem(action.allData),
        shdata: action.data,
        shtotal: action.totalPages,
        shparams: action.params,
        vhSpinner: action.spinner
      }
    case "GET_VEH_HIS_DATA_FILTER":
      return {
        ...state,
        shdata: action.data,
        shtotal: action.totalPages,
        shparams: action.params,
      };
    case "VEHIHIS_LOADING_SPINNER":
      return {
        ...state,
        vhSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_ALL_GPS_DATA":
      return {
        ...state,
        vchGpsData: action.gpsData,
        gpsSpinner: action.spinner
      }
    case "GPS_LOADING_SPINNER":
      return {
        ...state,
        gpsspinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_VEHICLE_HISTORY":
      return {
        ...state,
        vchGpsHistory: action.gpsHistoryData,
        hsitorySpinner: action.spinner
      }
    case "VEHICLE_HISTORY_LOADING_SPINNER":
      return {
        ...state,
        hsitorySpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_ALL_VEHIMIL_DATA":
      return {
        ...state,
        vmallData: initialMilItem(action.allData),
        vmdata: action.data,
        vmtotal: action.totalPages,
        vmparams: action.params,
        vmSpinner: action.spinner
      }
    case "GET_VEH_MIL_DATA_FILTER":
      return {
        ...state,
        vmdata: action.data,
        vmtotal: action.totalPages,
        vmparams: action.params,
      };
    case "VEHIMIL_LOADING_SPINNER":
      return {
        ...state,
        vmSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_ALL_VEHIPAP_DATA":
      return {
        ...state,
        vpallData: initialItem(action.allData),
        vpdata: action.data,
        vptotal: action.totalPages,
        vpparams: action.params,
        vpSpinner: action.spinner
      }
    case "GET_VEH_PAP_DATA_FILTER":
      return {
        ...state,
        vpdata: action.data,
        vptotal: action.totalPages,
        vpparams: action.params,
      };
    case "VEHIPAP_LOADING_SPINNER":
      return {
        ...state,
        vpSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "VEHBR_LOADING_SPINNER":
      return {
        ...state,
        vbSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_ALL_VEHBR_DATA":
      return {
        ...state,
        vbSpinner: action.spinner,
        vehBranchData: getData(action.allData)
      }
    default:
      return { ...state }
  }
}
export default VehicleReminders
