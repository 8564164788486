import axios from "axios";
import GlobalVariable from "../path/global";
const baseApiUrl = GlobalVariable.BASE_API_URL;
const monthnames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const monthfullnames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const authTypeOptions = [
  { value: "1", label: "Income Delete" },
  // { value: "2", label: "Attendance Summary" },
  // { value: "3", label: "Finance Summary" },
  { value: "4", label: "Students Remove" },
  { value: "5", label: "Employee Remove" },
  { value: "6", label: "Expenses Delete" },
  { value: "7", label: "Transfer Delete" },
  { value: "8", label: "Credit Alert SMS" },
  { value: "9", label: "Pay Summary" },
  { value: "12", label: "Bulk Exemption" },
  { value: "13", label: "Token Close" },
  { value: "14", label: "Bill Delete" },
];
const instituteOptions = [
  { value: "school", label: "School" },
  { value: "college", label: "College" },
  { value: "Retail", label: "Retail" },
  { value: "Hospital", label: "Hospital" },
  { value: "Bulk Sms", label: "Bulk Sms" },
  { value: "Construction", label: "Construction" },
  { value: "Gold Smith", label: "Gold Smith" },
  { value: "Hotel", label: "Hotel" },
  { value: "Association", label: "Association" },
];
const loginOptions = [
  { value: "1", label: "No Restrictions" },
  { value: "2", label: "Single Sign On" },
  { value: "3", label: "With Payment On" },
  { value: "4", label: "With OTP Only" },
  { value: "5", label: "With Payment & OTP Only" },
];
const attendenceOptions = [
  { value: "A", label: "Absent" },
  { value: "P", label: "Present" },
  { value: "L", label: "Leave As Empty" },
];
const numberOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
];
const numOptions = [
  { value: "1st", label: "1" },
  { value: "2nd", label: "2" },
  { value: "3rd", label: "3" },
  { value: "4th", label: "4" },
  { value: "5th", label: "5" },
  { value: "6th", label: "6" },
  { value: "7th", label: "7" },
  { value: "8th", label: "8" },
  { value: "9th", label: "9" },
  { value: "10th", label: "10" },
  { value: "11th", label: "11" },
  { value: "12th", label: "12" },
  { value: "13th", label: "13" },
  { value: "14th", label: "14" },
  { value: "15th", label: "15" },
  { value: "16th", label: "16" },
  { value: "17th", label: "17" },
  { value: "18th", label: "18" },
  { value: "19th", label: "19" },
  { value: "20th", label: "20" },
  { value: "21th", label: "21" },
  { value: "22th", label: "22" },
  { value: "23th", label: "23" },
  { value: "24th", label: "24" },
  { value: "25th", label: "25" },
  { value: "26th", label: "26" },
  { value: "27th", label: "27" },
  { value: "28th", label: "28" },
];
const errorresponse = "Something Went Wrong. Please approach technical team.";
export const DefaultConfig = () => {
  let userDetails = {};
  let empName = "";

  let desgRef = "";
  let empLastName = "";
  let empCompId = "";
  let empPastDate = "";
  let empAttExemLimit = 0;
  let empDept = 0;
  let swMentor = "";
  let naipunyaMentor = "";
  let phoneNoDisplay = "";
  let naipunyaStuSubjList = [];
  let imsApproval = "";
  let grpId = "";
  let companyId = "";
  let companyType = "";
  let companyName = "";
  let rmId = "";
  let companyAddr = "";
  let companyCity = '';
  let companyMail = "";
  let companyMobNo = "";
  let gstNo = "";
  let empSeries = "";
  let branchName = "";
  let gpsUname = "";
  let gpsPwd = "";
  let aliId = "";
  let paymentAlert = false;
  let concsLimit = "";
  let keyPplList = [];
  let keyPplDtlList = [];
  let RmDetails = {};
  let PaymentDetails = {};
  let employeeId = "";
  let finGrp = "";
  let idStatus = "";

  let bioCode = "";
  let uid = "";
  let pwd = "";
  let stuSno = "";
  let stuBusNo = "";
  let stuFeeCallDate = "";
  let acadGrp = "";
  let ip = "";
  let BranchIdList = [];
  let defaultYear = "";
  let dashboards = [];
  let siblingList = [];
  let SiblingDataMap = [];
  let logType = "";
  let apprSettings = [];
  let Classes = [];
  let Sections = [];
  let Designations = [];
  let findDesignation = "";
  let Subjects = [];
  let Mediums = [];
  let Degrees = [];
  let DeptCls = [];
  let ClassTeacher = [];
  let DiaryAlert = [];
  let Departments = [];
  let AcademicYear = [];
  let curDefultYear = 0;
  let curDefultYearCode = 0;
  let InvYear = {};
  let GVInvYear;
  let Branches = [];
  let GrpBranches = [];
  let SubBranchList = [];
  let InvCompDtls = {};
  let invId = '';
  let SchBranchList = [];
  let SchIdList = [];
  let GrpBranchList = [];
  let Banks = [];
  let SubBranchName = "";
  let authAxios;
  let authAxiosMulti;
  let authAxiosAtom;
  let StuBean = {};
  let feedbackMandate = false;
  let feedbackCollection = 0;
  let stuAtt = "";
  let applFee = "";
  let printType = "";
  let recOfcCopy = "";
  let dueInRec;
  let prFrmt;
  let tokenReportTo = "";
  let lateComesPerLeave = "";
  let leadBy = "";
  let url = '';
  let compLessonPlan = false;
  let compIdCards = false;
  let idTagType = [];
  let idTagWidth = [];
  let compPayRoll = false;
  let compSelfAss = false;
  let compSpeakWell = false;
  let compInvt = false;
  let compEnquiry = false;
  let compNaipunya = false;
  let instType = "";
  let empAtt = "";
  let stuDashboardOrder = {};
  let lastLogin = "";
  let resolvedToken;
  let discountStartDate = "";
  let discountEndDate = "";
  let discountPerc ="";
  let feeSmsCheck = "";
  let signShow = false;
  let stuImgShow = true;
  let skipFinInStuApp = false;
  let Grades = [];
  let RdvHSGrades = [];
  let MontfortGrades = [];
  let AllottedSubjects = [];
  let feeCategories = [];
  let senderId = "";
  let CollectMode = "";
  let CompanyBean = "";
  let LoginTime = "";
  let partCollect = 0;
  let pocketInLedger = false;
  let DynamicStudent = {
    "dsId": {
      "stuRef": 0,
      "cls": 0,
      "sec": 0,
      "med": 0,
      "dept": 0
    },
    "stgRef": null,
    "hst": null,
    "fc": null,
    "fee": null,
    "concs": null,
    "paid": null,
    "splFee": null,
    "tuitionFee": null,
    "trptFee": null,
    "hstlFee": null,
    "otherFee": null,
    "termFee": null,
    "termDate": null,
    "att": null,
    "logId": null,
    "sysTime": null,
    "busNoRef": "0",
    "stuSchlr": null
  };
  let DynamicStudentDtls = {
    "stuRef": 0,
    "cls": 0,
    "sec": 0,
    "med": 0,
    "dept": 0
  };

  if (localStorage.getItem("userData")) {
    userDetails = JSON.parse(localStorage.getItem("userData"));
    //approval settings
    apprSettings = userDetails["ApprovalSettings"];
    //BranchIdList
    BranchIdList = userDetails["BranchIdList"];

    //Default Year
    defaultYear = userDetails["defaultYear"];

    //Dashboard Access
    dashboards = userDetails["dashboards"];

    //Sibling List
    siblingList = userDetails["siblingList"];

    SiblingDataMap =
    siblingList &&
    siblingList.map((sib) => ({
      value: sib.sno,
      label: `Name: ${sib.nameF}${sib.nameL ? ` ${sib.nameL}` : ""} 
    - S/o: ${sib.fathName ? sib.fathName : ""} 
    - Reg No: ${sib.rollNo ? sib.rollNo : ""} 
    - Adm. No: ${sib.admNo} 
    - Cls: ${Classes && Classes.find((cc) => cc.value === sib.cls)
          ? Classes.find((cc) => cc.value === sib.cls).label
          : "-"
        } 
    - Sec: ${Sections && Sections.find((cc) => cc.value === sib.sec)
          ? Sections.find((cc) => cc.value === sib.sec).label
          : "-"
        } 
          
    - Mob. No: ${sib.mobF ? sib.mobF : ""}`,
      name: `${sib.nameF}${sib.nameL ? ` ${sib.nameL}` : ""}`,
      admNo: sib.admNo,
      compId: sib.compId,
      gender: sib.gender,
      rollNo: sib.rollNo,
      cls: sib.cls,
      sec: sib.sec,
      fathName: sib.fathName,
      feeCatg: sib.feeCatg,
      dob: sib.dob,
      fathName: sib.fathName,
      mobF: sib.mobF,
      deptRef: sib.deptRef,
      stageRef: sib.stageRef,
      joinYear: sib.joinYear,
      feeClrYear: sib.feeClrYear,
      addr: sib.addr
    }));

    //Login Time
    LoginTime = userDetails["sysTime"];

    // LogType
    logType =
      userDetails["LogType"] === "stuRef"
        ? "stu"
        : userDetails["LogType"] === "admin"
        ? "emp"
        : userDetails["LogType"];

    // empName
    empName = userDetails["EmpBean"] && userDetails["EmpBean"]["firstName"];

    // Designation Reference
    desgRef = userDetails["EmpBean"] && userDetails["EmpBean"]["desgRef"];

    // empLastName
    empLastName = userDetails["EmpBean"] && userDetails["EmpBean"]["lastName"];

    //Employee Company Id
    empCompId = userDetails["EmpBean"] && userDetails["EmpBean"]["compId"];

    //Past Date
    empPastDate = userDetails["EmpBean"] && userDetails["EmpBean"]["pastDate"];

    //Emp. Att. Exemption Limit 
    empAttExemLimit = userDetails["EmpBean"] && userDetails["EmpBean"]["attExemLimit"];

    //Emp. Department
    empDept = userDetails["EmpBean"] && userDetails["EmpBean"]["empDept"];

    //Speakwell Mentor
    swMentor = userDetails["EmpBean"] && userDetails["EmpBean"]["swMentor"];

    //Naipunya Mentor
    naipunyaMentor = userDetails["EmpBean"] && userDetails["EmpBean"]["naipunyaMentor"];

    //Naipunya Mentor
    phoneNoDisplay = userDetails["EmpBean"] && userDetails["EmpBean"]["phNoDisp"];

    //Naipunya Subject List
    naipunyaStuSubjList = userDetails["naipunyaStuSubjList"]

    // company Type
    companyType =
      userDetails["CompanyBean"] && userDetails["CompanyBean"]["compType"];

    grpId = userDetails["CompanyBean"] && userDetails["CompanyBean"]["grpId"];
    // company id
    companyId =
      userDetails["CompanyBean"] && userDetails["CompanyBean"]["compId"];

    // sender id
    senderId =
      userDetails["CompanyBean"] && userDetails["CompanyBean"]["senderId"];

    // empSeries
    empSeries =
      userDetails["CompanyBean"] && userDetails["CompanyBean"]["empSeries"];

    // company name
    companyName =
      userDetails["CompanyBean"] && userDetails["CompanyBean"]["name"];

    // company RM ID
    rmId = userDetails["CompanyBean"] && userDetails["CompanyBean"]["rmId"];

    // company addr
    companyAddr =
      userDetails["CompanyBean"] && userDetails["CompanyBean"]["addr"];

    companyCity =
      userDetails["CompanyBean"] && userDetails["CompanyBean"]["city"];

    // company mail
    companyMail =
      userDetails["CompanyBean"] && userDetails["CompanyBean"]["email"];

    // company Mobile Number
    companyMobNo =
      userDetails["CompanyBean"] && userDetails["CompanyBean"]["phNo"];

    // company GST Number
    gstNo = userDetails["CompanyBean"] && userDetails["CompanyBean"]["gstId"];

    // branch name
    branchName =
      userDetails["CompanyBean"] && userDetails["CompanyBean"]["branchName"];

    // gps Uname
    gpsUname =
      userDetails["CompanyBean"] && userDetails["CompanyBean"]["gpsUserName"];

    // gps pwd
    gpsPwd =
      userDetails["CompanyBean"] && userDetails["CompanyBean"]["gpsPassword"];

      // gps pwd
    aliId =
    userDetails["CompanyBean"] && userDetails["CompanyBean"]["aliId"];

    //company lock Boolean
    paymentAlert = userDetails["CompanyBean"] && userDetails["CompanyBean"]["paymentAlert"];

    //Concession Limit for Branch
    concsLimit = userDetails["CompanyBean"] && userDetails["CompanyBean"]["custTarget"];

    //Key Peoples List for Branch
    keyPplList = userDetails["CompanyBean"] && userDetails["CompanyBean"]["keyPplList"];

    //Key Peoples Detailed List for Branch
    keyPplDtlList = userDetails["CompanyBean"] && userDetails["CompanyBean"]["keyPeoplesList"];

    // RM Details
    RmDetails = userDetails["Rmdetails"];

    //CustAmtModel
    PaymentDetails = userDetails["custAmtModel"]

    // employee id
    employeeId = userDetails["EmpBean"] && userDetails["EmpBean"]["empId"];
    //ip
    ip = userDetails["EmpBean"] && userDetails["EmpBean"]["ip"];
    //bioCode
    bioCode = userDetails["EmpBean"] && userDetails["EmpBean"]["bioCode"];
    //uid
    uid = userDetails["EmpBean"] && userDetails["EmpBean"]["uid"];
    //uid
    pwd = userDetails["EmpBean"] && userDetails["EmpBean"]["pwd"];
    // //stuSno
    // stuSno = userDetails["StuBean"] && userDetails["StuBean"]["sno"];
    //stuSno
    stuSno = userDetails["dynamic_Student"] && userDetails["dynamic_Student"]["dsId"] && userDetails["dynamic_Student"]["dsId"]["stuRef"];
    //stuBusNo
    stuBusNo = userDetails["StuBean"] && userDetails["StuBean"]["busNoRef"];
    //Fee Call Date
    stuFeeCallDate = userDetails["StuBean"] && userDetails["StuBean"]["feeCallDate"];

    // fingrp id
    finGrp = userDetails["ImsBean"] && userDetails["ImsBean"]["finGrp"];
    //ID Card Status
    idStatus = userDetails["ImsBean"] && userDetails["ImsBean"]["idCard"];

    // acadGrp
    acadGrp = userDetails["ImsBean"] && userDetails["ImsBean"]["acadGrp"];
    //StuAtt
    stuAtt = userDetails["ImsBean"] && userDetails["ImsBean"]["stuDefAtt"];

    //Applicstion Fee
    applFee = userDetails["ImsBean"] && userDetails["ImsBean"]["applFee"];

    //Print Type Fee
    printType = userDetails["ImsBean"] && userDetails["ImsBean"]["prntStn"];

    //Partial Collect
    partCollect = userDetails["ImsBean"] && userDetails["ImsBean"]["partCollect"]

    //Pocket Money in Ledger
    pocketInLedger = userDetails["ImsBean"] && userDetails["ImsBean"]["pocketInLedger"]

    //Dynamic Student
    DynamicStudent = userDetails["dynamic_Student"] && userDetails["dynamic_Student"]

    //Dynamic Student Dtls
    DynamicStudentDtls = (userDetails["dynamic_Student"] && userDetails["dynamic_Student"]["dsId"]) === null ? {"stuRef": 0, "cls": 0, "sec": 0, "med": 0, "dept": 0} : (userDetails["dynamic_Student"] && userDetails["dynamic_Student"]["dsId"])

    //Token Report
    tokenReportTo =
      userDetails["ImsBean"] && userDetails["ImsBean"]["tokenReport"];
    
    //Late Comes Per Leave
    lateComesPerLeave =
      userDetails["ImsBean"] && userDetails["ImsBean"]["lateComes"];

    //Lead By
    leadBy = userDetails["CompanyBean"] && userDetails["CompanyBean"]["leadBy"];

    //Lead By
    url = userDetails["CompanyBean"] && userDetails["CompanyBean"]["url"];

    //Module Using Conditions
    compPayRoll = userDetails["CompanyBean"] && userDetails["CompanyBean"]["payRoll"];
    compLessonPlan = userDetails["CompanyBean"] && userDetails["CompanyBean"]["lessonPlan"];
    compPayRoll = userDetails["CompanyBean"] && userDetails["CompanyBean"]["payRoll"];
    compSelfAss = userDetails["CompanyBean"] && userDetails["CompanyBean"]["selfAss"];
    compSpeakWell = userDetails["CompanyBean"] && userDetails["CompanyBean"]["speakWell"];
    compInvt = userDetails["CompanyBean"] && userDetails["CompanyBean"]["invt"];
    compEnquiry = userDetails["CompanyBean"] && userDetails["CompanyBean"]["enquiry"];
    compNaipunya = userDetails["CompanyBean"] && userDetails["CompanyBean"]["naipunya"];
    compIdCards = userDetails["CompanyBean"] && userDetails["CompanyBean"]["idCards"];

    idTagType = [
      { value: 70, label: "Student Tag 70cm", type: "stuTag70" },
      { value: 80, label: "Student Tag 80cm", type: "stuTag80" },
      { value: 90, label: "Student Tag 90cm", type: "stuTag90" },
      { value: 20, label: "Employee Tag 90cm", type: "empTag90" },
    ];

    idTagWidth = [
      {value: "12mm", label: "12 MM"},
      {value: "16mm", label: "16 MM"},
      {value: "20mm", label: "20 MM"}
    ]

    //receipt copy
    recOfcCopy = userDetails["ImsBean"] && userDetails["ImsBean"]["recOfcCopy"];

    //Due in Receipt
    dueInRec = userDetails["ImsBean"] && userDetails["ImsBean"]["dueInRec"];

    //Progress Report Format
    prFrmt = userDetails["ImsBean"] && userDetails["ImsBean"]["progFromat"];

    //Inst. Type
    instType = userDetails["ImsBean"] && userDetails["ImsBean"]["instType"];

    //EmpAtt
    empAtt = userDetails["ImsBean"] && userDetails["ImsBean"]["defAtt"];

    //Parent App
    stuDashboardOrder = userDetails["parentApp"]

    //Last Login Date
    lastLogin = userDetails["lastLogin"]
    
    //Resolved Token
    resolvedToken = userDetails["resolvedToken"]

    //Discount Dates
    discountStartDate = userDetails["discount"] && userDetails["discount"]["fromDate"];
    discountEndDate = userDetails["discount"] && userDetails["discount"]["toDate"];
    discountPerc = userDetails["discount"] && userDetails["discount"]["discount"];

    //feeSmsCheck
    feeSmsCheck =
      userDetails["ImsBean"] && userDetails["ImsBean"]["feeSmsCheck"];

    //Signature Show or Not in Receipt
    signShow =
    userDetails["ImsBean"] && userDetails["ImsBean"]["signature"];

    //Signature Show or Not in Receipt
    stuImgShow =
    userDetails["ImsBean"] && userDetails["ImsBean"]["stuImage"];

    //skipFinanceInStuApp
    skipFinInStuApp =
      userDetails["ImsBean"] && userDetails["ImsBean"]["skipFinanceInStuApp"];

    // jwt token set to axios header
    imsApproval =
      userDetails["ImsBean"] && userDetails["ImsBean"]["apprvLevel"];
    authAxios = axios.create({
      baseURL: baseApiUrl,
      headers: { Authorization: `Bearer ${userDetails.jwtToken}` },
    });
    authAxiosMulti = axios.create({
      baseURL: baseApiUrl,
      headers: {
        Authorization: `Bearer ${userDetails.jwtToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    authAxiosAtom = axios.create({
      baseURL: baseApiUrl,
      headers: {
        Authorization: `Bearer ${userDetails.jwtToken}`,
        "Content-Type": "application/json",
      },
    });
    // Fee Categories
    feeCategories = userDetails["FeeCategories"]
      ? userDetails["FeeCategories"].map((sec) => ({
          value: sec.catg,
          label: sec.descr,
        }))
      : [];
    feeCategories.unshift({ value: "All Categories", label: "All Categories" });
    // classes
    Classes = userDetails["Classes"]
      ? userDetails["Classes"].map((cls) => ({
          value: cls.id,
          label: cls.name,
          acr: cls.acr,
        }))
      : [];
    Classes.unshift({ value: 0, label: "All Classes", acr: "All Classes" });
    // Sections
    Sections = userDetails["Sections"]
      ? userDetails["Sections"].map((sec) => ({
          value: sec.id,
          label: sec.name,
          acr: sec.acr,
        }))
      : [];
    Sections.unshift({ value: 0, label: "All Sections", acr: "All Sections" });
    // Designations
    Designations = userDetails["Designations"]
      ? userDetails["Designations"].map((desig) => ({
          value: desig.id,
          label: desig.name,
          acr: desig.acronym,
        }))
      : [];
    Designations.unshift({ value: 0, label: "All Designations" });

    findDesignation = Designations && Designations.find((ff) => ff.value === desgRef) && Designations.find((ff) => ff.value === desgRef).label

    // Mediums
    Mediums = userDetails["Mediums"]
      ? userDetails["Mediums"].map((med) => ({
          value: med.id,
          label: med.name,
        }))
      : [];
    Mediums.unshift({ value: 0, label: "All Mediums" });
    // Subjects
    Subjects = userDetails["Subject"]
      ? userDetails["Subject"].map((subj) => ({
          value: subj.id,
          label: subj.name,
          acr: subj.acr,
        }))
      : [];
    // Dept Class Sections Mapping
    DeptCls = userDetails["DeptCls"];

    // Class Teacher Mapping
    ClassTeacher = userDetails["ClassSec"];

    //Diary Alert 
    DiaryAlert = userDetails["dairyAlert"];

    // Degrees
    Degrees = userDetails["Degrees"]
      ? userDetails["Degrees"].map((deg) => ({
          value: deg.id,
          label: deg.name,
        }))
      : [];

    // Departments
    Departments = userDetails["Departments"]
      ? userDetails["Departments"].map((dep) => ({
          value: dep.id,
          label: dep.name,
          acr: dep.acr,
        }))
      : [];
    // Departments.unshift({ value: 0, label: "All Departments" });
    // AcademicYear
    AcademicYear = userDetails["AcademicYear"]
      ? userDetails["AcademicYear"].map((academic) => ({
          value: academic.id,
          label: academic.name,
          curYear: academic.currentYear,
          prevYear: academic.prevYear,
          curAdmnYear: academic.admnYear,
        }))
      : [];

    curDefultYear = AcademicYear && AcademicYear.find((ff) => ff.curYear === true) && AcademicYear.find((ff) => ff.curYear === true).value;
    curDefultYearCode = AcademicYear && AcademicYear.find((ff) => ff.curYear === true) && AcademicYear.find((ff) => ff.curYear === true).label;
    
    InvYear = userDetails["invYear"]
    GVInvYear = userDetails["invYear"] && userDetails["invYear"]['sno']

    // Branches
    // const CompanyBranch =
    //   userDetails["CompanySubBranch"] &&
    //   userDetails["CompanySubBranch"].find((find) => find.compId === companyId);
    // if (CompanyBranch) {
    //   if (CompanyBranch.subBranchCompList) {
    //     Branches = CompanyBranch.subBranchCompList.map((subbranch) => ({
    //       value: subbranch.compId,
    //       label: subbranch.branchName,
    //     }));
    //   } else {
    //     Branches.push({
    //       value: CompanyBranch.compId,
    //       label: CompanyBranch.branchName,
    //     });
    //   }
    //   Branches.unshift({ value: 0, label: "All Branches" });
    // }

    //SubBranches
    SubBranchList = userDetails["SubBranchList"]
      ? userDetails["SubBranchList"]
      : [];
    SchBranchList = SubBranchList.filter((item) => item.compType === "sch" || item.compType === 'alliance').map(
      (subbranch) => ({
        value: subbranch.compId,
        label: `${subbranch.branchName} (${subbranch.compId})`,
        branchName: subbranch.branchName,
      })
    );
    SchIdList = SubBranchList.filter((item) => item.compType === "sch").map(
      (subbranch) => subbranch.compId
    );

    GrpBranchList = userDetails["GrpBranchList"]
      ? userDetails["GrpBranchList"]
      : [];
    GrpBranches = GrpBranchList.map((grpBranch) => ({
      value: grpBranch.compId,
      label: grpBranch.compId + " - " + grpBranch.branchName,
    }));
    Branches = SubBranchList.map((subbranch) => ({
      value: subbranch.compId,
      label: subbranch.branchName + " " + `(${subbranch.compId})`,
      branchName: subbranch.branchName,
    }));
    if (!Branches.find((find) => find.value === companyId)) {
      Branches.push({
        value: companyId,
        label: companyName,
        branchName: companyName,
      });
    }

    InvCompDtls = userDetails["invCompDtls"]
    invId = userDetails["invCompDtls"] && userDetails["invCompDtls"]["compId"]

    //BankList
    Banks = userDetails["Banks"]
      ? userDetails["Banks"].map((bank) => ({
          value: bank.id,
          label: bank.bankName,
          compName: bank.compName,
          email: bank.email,
          gstNo: bank.gstNo,
          phNo: bank.phNo,
          addr: bank.addr,
        }))
      : [];

    //Collect Mode 
    CollectMode = userDetails["CompanyBean"] && userDetails["CompanyBean"]["collectMode"];
    
    //Company Bean
    CompanyBean = userDetails["CompanyBean"]

    StuBean = userDetails["StuBean"] ? userDetails["StuBean"] : {};
    Grades = userDetails["Grades"] ? userDetails["Grades"] : [];
    RdvHSGrades = [
      {"sno": 1, "grade": "A", "maxPercent": 100, "minPercent": 75},
      {"sno": 2, "grade": "B", "maxPercent": 74, "minPercent": 65},
      {"sno": 3, "grade": "C", "maxPercent": 64, "minPercent": 51},
      {"sno": 4, "grade": "D", "maxPercent": 50, "minPercent": 45},
      {"sno": 5, "grade": "E", "maxPercent": 44, "minPercent": 35},
      {"sno": 6, "grade": "F", "maxPercent": 34, "minPercent": 0}
    ]

    MontfortGrades = [
      {"sno": 1, "grade": "A1", "maxPercent": 50, "minPercent": 45},
      {"sno": 2, "grade": "A2", "maxPercent": 44, "minPercent": 40},
      {"sno": 3, "grade": "B1", "maxPercent": 39, "minPercent": 35},
      {"sno": 4, "grade": "B2", "maxPercent": 34, "minPercent": 30},
      {"sno": 1, "grade": "C1", "maxPercent": 29, "minPercent": 25},
      {"sno": 1, "grade": "C2", "maxPercent": 24, "minPercent": 20},
      {"sno": 1, "grade": "D", "maxPercent": 19, "minPercent": 15},
      {"sno": 1, "grade": "E", "maxPercent": 14, "minPercent": 10},
      {"sno": 1, "grade": "E", "maxPercent": 9, "minPercent": 5},
      {"sno": 1, "grade": "E", "maxPercent": 4, "minPercent": 0},   
    ]

    AllottedSubjects = userDetails["AllottedSubjects"]
      ? userDetails["AllottedSubjects"]
      : [];

    feedbackMandate = userDetails["feedbackMandate"]
      ? userDetails["feedbackMandate"]
      : false;
    feedbackCollection = userDetails["feedbackCollection"]
      ? userDetails["feedbackCollection"]
      : 0;
  }

  // date format conversion for month
  const DateFormatMonth = (value) => {
    if (value) {
      const monthname = monthnames[new Date(value).getMonth()];
      const datevalue = `0${new Date(value).getDate()}`.slice(-2);
      const yearvalue = new Date(value).getFullYear();
      return `${datevalue} ${monthname}, ${yearvalue}`;
    } else {
      return "";
    }
  };

  //date formate conversion for pdf
  const utc = new Date().toJSON().slice(0, 10).replace(/-/g, "/");

  const DateforPdf = (value) => {
    if (value) {
      const monthname = monthnames[new Date(value).getMonth()];
      const datevalue = `0${new Date(value).getDate()}`.slice(-2);
      const yearvalue = new Date(value).getFullYear();
      return `${datevalue}-${monthname}-${yearvalue}`;
    } else {
      return "";
    }
  };
  const date = DateforPdf(utc);

  //time formate for pdf
  const CurrentTime = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  // date format conversion for month
  const DateFormat = (value) => {
    if (value) {
      return `${new Date(value).getFullYear()}-${`0${
        new Date(value).getMonth() + 1
      }`.slice(-2)}-${`0${new Date(value).getDate()}`.slice(-2)}`;
    } else {
      return "";
    }
  };

  // date format conversion for date month year
  const DateOrderFormat = (value) => {
    if (value) {
      return `${`0${new Date(value).getDate()}`.slice(-2)}-${`0${
        new Date(value).getMonth() + 1
      }`.slice(-2)}-${new Date(value).getFullYear()}`;
    } else {
      return "";
    }
  };

  const phoneNumberMask = (phoneNumber) => {
    if (phoneNumber != null) {
    // Convert phoneNumber to string
    phoneNumber = phoneNumber.toString();
    // Assuming phoneNumber is in the format '1234567890'
    const lastTwoDigits = phoneNumber.slice(-2);
    const maskedPart = phoneNumber.slice(0, -2).replace(/\d/g, '*');
    return maskedPart + lastTwoDigits;
    } else {
      return null
    }
  };

  const OTPMask = (optNo) => {
    if (optNo != null) {
    // Convert optNo to string
    optNo = optNo.toString();
    // Assuming optNo is in the format '1234567890'
    const lastTwoDigits = optNo;
    const maskedPart = optNo.replace(/\d/g, '*');
    return maskedPart + lastTwoDigits;
    } else {
      return null
    }
  };

  // converting image into string
  const ImgFormat = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // time format
  const TimeFormat = (value) => {
    if (value) {
      return `${`0${new Date(value).getHours()}`.slice(-2)}:${`0${new Date(
        value
      ).getMinutes()}`.slice(-2)}:${`0${new Date(value).getSeconds()}`.slice(
        -2
      )}`;
    } else {
      return "";
    }
  };

  // time format
  const TimeFormatNotSec = (value) => {
    if (value) {
      return `${`0${new Date(value).getHours()}`.slice(-2)}:${`0${new Date(
        value
      ).getMinutes()}`.slice(-2)}`;
    } else {
      return "";
    }
  };

  const DateTimeFormat = (value) => {
    if (value) {
      const monthname = monthnames[new Date(value).getMonth()];
      const datevalue = `0${new Date(value).getDate()}`.slice(-2);
      const yearvalue = new Date(value).getFullYear();
      var d = new Date(value);
      var n = d.toLocaleString([], { hour: "2-digit", minute: "2-digit" });
      return `${datevalue} ${monthname}, ${yearvalue} | ${n}`;
    } else {
      return "";
    }
  };

  // amounts
  const AmountFormat = (dataArray) => {
    let x = dataArray;
    x = Number.parseFloat(x).toFixed(3);
    x = x.toString();
    let afterPoint = "";
    if (x.indexOf(".") > 0) {
      afterPoint = x.substring(x.indexOf("."), x.length);
    }
    x = Math.floor(x);
    x = x.toString();
    let lastThree = x.substring(x.length - 4);
    const otherNumbers = x.substring(0, x.length - 4);
    if (otherNumbers !== "") {
      if (otherNumbers === "-") {
        lastThree = `${lastThree}`;
      } else {
        lastThree = `,${lastThree}`;
      }
    }
    const res =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      lastThree +
      afterPoint;
    if (res) {
      return `${res}`;
    } else {
      return ``;
    }
  };

  const AmountFormat2Digits = (dataArray) => {
    let x = dataArray;
    x = Number.parseFloat(x).toFixed(2);
    x = x.toString();
    let afterPoint = "";
    if (x.indexOf(".") > 0) {
      afterPoint = x.substring(x.indexOf("."), x.length);
    }
    x = Math.floor(x);
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    const otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") {
      if (otherNumbers === "-") {
        lastThree = `${lastThree}`;
      } else {
        lastThree = `,${lastThree}`;
      }
    }
    const res =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      lastThree +
      afterPoint;
    if (res) {
      return `${res}`;
    } else {
      return ``;
    }
  };

  // amount format without points
  const AmtFormat = (dataArray) => {
    let x = dataArray;
    x = Number.parseFloat(x).toFixed(2);
    x = x.toString();
    x = Math.floor(x);
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    const otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") {
      if (otherNumbers === "-") {
        lastThree = `${lastThree}`;
      } else {
        lastThree = `,${lastThree}`;
      }
    }
    const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    if (res) {
      return `${res}`;
    } else {
      return ``;
    }
  };

  //Amount with Decimals format
  const MarkFormat = (number) => {
    if (Number.isInteger(number)) {
        return number.toString();
    } else {
        return number.toFixed(2);
    }
}

  const AmtDecFormat = (n) => {
    // Convert the input to a number
    const num = Number(n);

    // Check if the number is negative
    const isNegative = num < 0;

    // Get the absolute value of the number
    const absNum = Math.abs(num);

    // Separate the integer part and the decimal part
    const integerPart = Math.floor(absNum).toLocaleString('en-IN');
    const decimalPart = (absNum % 1 !== 0) ? absNum.toFixed(2).split('.')[1] : "";

    // Combine the integer and decimal parts
    const formattedNum = decimalPart ? `${integerPart}.${decimalPart}` : `${integerPart}`;

    // Add the negative sign if the number is negative
    return isNegative ? `-${formattedNum}` : formattedNum;
  };


  //Marks Format
  const MarksFormat = (n) => {
    const num = Number(n);
    const integerPart = Math.floor(num).toLocaleString('en-IN', { maximumFractionDigits: 0 });
    const decimalPart = (num % 1 !== 0) ? num.toFixed(1).split(".")[1] : '';
    const formatNum = decimalPart ? `${integerPart}.${decimalPart}` : `${integerPart}`;
    return formatNum;
  };

  // New Amount Format
  const NAmtFormat = (dataArray) => {
    var x = dataArray;
    x = Number.parseFloat(x);
    x = x.toString();
    var afterPoint = "";
    if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);
    x = Math.floor(x);
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      lastThree +
      afterPoint;
    if (res) {
      return `${res}`;
    } else {
      return ``;
    }
  };

  //number to word converter
  const arr = (x) => Array.from(x);
  const num = (x) => Number(x) || 0;
  const str = (x) => String(x);
  const isEmpty = (xs) => xs.length === 0;
  const take = (n) => (xs) => xs.slice(0, n);
  const drop = (n) => (xs) => xs.slice(n);
  const reverse = (xs) => xs.slice(0).reverse();
  const comp = (f) => (g) => (x) => f(g(x));
  const not = (x) => !x;
  const chunk = (n) => (xs) =>
    isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

  // numToWords :: (Number a, String a) => a -> String
  const NumToWords = (n) => {
    const a = [
      "", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine",
      "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen",
      "seventeen", "eighteen", "nineteen"
    ];
  
    const b = [
      "", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"
    ];
  
    const g = [
      "", "thousand", "lakh", "crore"
    ];
  
    const num = (x) => Number(x) || 0;
  
    const makeGroup = ([ones, tens, huns]) => {
      return [
        num(huns) === 0 ? "" : a[huns] + " hundred ",
        num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + " ") || "",
        a[tens + ones] || a[ones]
      ].join("").trim();
    };
  
    const thousand = (group, i) => (group === "" ? group : `${group} ${g[i]}`);
  
    if (typeof n === "number") return NumToWords(String(n));
    if (n === "0") return "zero";
  
    const pad = (num, size) => {
      let s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    };
  
    const makeNumberGroups = (str) => {
      let groups = [];
      if (str.length > 3) {
        groups.push(str.slice(-3));
        str = str.slice(0, -3);
      } else {
        groups.push(str);
        str = "";
      }
  
      while (str.length > 0) {
        groups.push(str.slice(-2));
        str = str.slice(0, -2);
      }
  
      return groups.reverse();
    };
  
    const groups = makeNumberGroups(n);
  
    let wordGroups = groups.map((group, i) => {
      return makeGroup(pad(group, 3).split('').reverse());
    });
  
    let result = wordGroups.map((group, i) => thousand(group, wordGroups.length - i - 1)).filter(group => group.trim() !== "").join(" ");
  
    return result;
  };

  //Convert Date of Birth To Words
  function convertDateToWords(date) {
    const months = [
      "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December"
    ];
  
    const dayWords = [
      "First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh", 
      "Eighth", "Ninth", "Tenth", "Eleventh", "Twelfth", "Thirteenth", 
      "Fourteenth", "Fifteenth", "Sixteenth", "Seventeenth", "Eighteenth", 
      "Nineteenth", "Twentieth", "Twenty-first", "Twenty-second", 
      "Twenty-third", "Twenty-fourth", "Twenty-fifth", "Twenty-sixth", 
      "Twenty-seventh", "Twenty-eighth", "Twenty-ninth", "Thirtieth", "Thirty-first"
    ];
  
    const [day, month, year] = date.split('-').map(Number);
  
    function convertYearToWords(year) {
      const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
      const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
      const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
      
      let words = "";
  
      if (year >= 1000) {
        words += ones[Math.floor(year / 1000)] + " Thousand ";
        year %= 1000;
      }
  
      if (year >= 100) {
        words += ones[Math.floor(year / 100)] + " Hundred ";
        year %= 100;
      }
  
      if (year >= 20) {
        words += tens[Math.floor(year / 10)] + " ";
        year %= 10;
      } else if (year >= 10) {
        words += teens[year - 10] + " ";
        year = 0;
      }
  
      if (year > 0) {
        words += ones[year] + " ";
      }
  
      return words.trim();
    }
  
    const dayInWords = dayWords[day - 1];
    const monthName = months[month - 1];
    const yearInWords = convertYearToWords(year);
  
    return `${dayInWords} ${monthName} ${yearInWords}`;
  }
  
  // table columns sorting
  const sorter = (sortname, sortorder, filteredData) => {
    filteredData.sort((a, b) => {
      if (typeof a[sortname] === "number" && typeof b[sortname] === "number") {
        if (sortorder === "asc") {
          return parseInt(a[sortname]) > parseInt(b[sortname])
            ? 1
            : parseInt(a[sortname]) < parseInt(b[sortname])
            ? -1
            : 0;
        } else {
          return parseInt(b[sortname]) > parseInt(a[sortname])
            ? 1
            : parseInt(b[sortname]) < parseInt(a[sortname])
            ? -1
            : 0;
        }
      } else if (
        typeof a[sortname] === "string" &&
        typeof b[sortname] === "string"
      ) {
        if (sortorder === "asc") {
          return a[sortname].toLowerCase() > b[sortname].toLowerCase()
            ? 1
            : a[sortname].toLowerCase() < b[sortname].toLowerCase()
            ? -1
            : 0;
        } else {
          return b[sortname].toLowerCase() > a[sortname].toLowerCase()
            ? 1
            : b[sortname].toLowerCase() < a[sortname].toLowerCase()
            ? -1
            : 0;
        }
      } else {
        if (sortorder === "asc") {
          return a[sortname] > b[sortname]
            ? 1
            : a[sortname] < b[sortname]
            ? -1
            : 0;
        } else {
          return b[sortname] > a[sortname]
            ? 1
            : b[sortname] < a[sortname]
            ? -1
            : 0;
        }
      }
    });
    return filteredData;
  };

  //convering decimal number into words
  const convertNumberToWords = (amount) => {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      let value = "";
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };

  // rupee symbols
  const amtSymbol = "₹";

  // subjects crud
  const Electives = [
    { value: -1, label: "Elective 1" },
    { value: -2, label: "Elective 2" },
    { value: -3, label: "Elective 3" },
    { value: -4, label: "Elective 4" },
    { value: -5, label: "Elective 5" },
    { value: -6, label: "Elective 6" },
    { value: -7, label: "Elective 7" },
    { value: -8, label: "Elective 8" },
    { value: -9, label: "Elective 9" },
    { value: -10, label: "Elective 10" },
  ];

  // subject mappings
  const subjectsArr = [
    {
      id: 1,
      subjectOrder: "Subject - 01",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 2,
      subjectOrder: "Subject - 02",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 3,
      subjectOrder: "Subject - 03",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 4,
      subjectOrder: "Subject - 04",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 5,
      subjectOrder: "Subject - 05",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 6,
      subjectOrder: "Subject - 06",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 7,
      subjectOrder: "Subject - 07",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 8,
      subjectOrder: "Subject - 08",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 9,
      subjectOrder: "Subject - 09",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 10,
      subjectOrder: "Subject - 10",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 11,
      subjectOrder: "Subject - 11",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 12,
      subjectOrder: "Subject - 12",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 13,
      subjectOrder: "Subject - 13",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 14,
      subjectOrder: "Subject - 14",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 15,
      subjectOrder: "Subject - 15",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 16,
      subjectOrder: "Subject - 16",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 17,
      subjectOrder: "Subject - 17",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 18,
      subjectOrder: "Subject - 18",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 19,
      subjectOrder: "Subject - 19",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
    {
      id: 20,
      subjectOrder: "Subject - 20",
      subjectName: "",
      employeeName: "",
      subjectId: "",
      employeeId: "",
    },
  ];

  const localPrcCardPackages = userDetails["priceCardDetails"];
  const basePack = userDetails["priceCardDetails"] && userDetails["priceCardDetails"]['basepack'];
  const attPack = userDetails["priceCardDetails"] && userDetails["priceCardDetails"]['att'];
  const incomeRecords = userDetails["incRecords"] ? userDetails["incRecords"] : 0;
  const expenseRecords = userDetails["expRecords"] ? userDetails["expRecords"] : 0;
  const examRecords = userDetails["examsCount"] ? userDetails["examsCount"] : 0;

  // Packages List
  const PackagesList = [
    // {
      
    //   value: 0,
    //   label: 'Free Information',
    //   quantity: '',
    //   checked: true,
    //   catg: "free"
    // },
    {
      
      value: localPrcCardPackages && localPrcCardPackages.basepack > 0 ? localPrcCardPackages.basepack : '',
      label: 'Base Pack',
      quantity: 'Per Student',
      checked: true,
      catg: "stu",
      ref: "erp_basePack"
    },
    // {
      
    //   value: 20,
    //   label: 'Expense Management',
    //   quantity: 'Per Student',
    //   checked: true,
    //   catg: "stu",
    //   ref: "erp_exp"
    // },
    {
      value: localPrcCardPackages && localPrcCardPackages.att > 0 ? localPrcCardPackages.att : '',
      label: 'Attendance Plan',
      quantity: 'Per Student',
      checked: true,
      catg: "stu",
      ref: "erp_att"
    },
    { 
      value: localPrcCardPackages && localPrcCardPackages.lessonPlan > 0 ? localPrcCardPackages.lessonPlan : '',
      label: 'Lesson Plan',
      quantity: 'Per Student',
      checked: true,
      catg: "stu",
      ref: "erp_lessonPlan"
    },
    {
      
      value: localPrcCardPackages && localPrcCardPackages.payroll > 0 ? localPrcCardPackages.payroll : '',
      label: 'Payroll',
      quantity: 'Per Employee',
      checked: true,
      catg: "emp",
      ref: "erp_payroll"
    },
    {
      
      value: localPrcCardPackages && localPrcCardPackages.invt > 0 ? localPrcCardPackages.invt : '',
      label: 'Inventory Module',
      quantity: 'Complete Module',
      checked: true,
      catg: "module",
      ref: "erp_invt"
    },
    {
      
      value: localPrcCardPackages && localPrcCardPackages.enquiry > 0 ? localPrcCardPackages.enquiry : '',
      label: 'Enquiry Module',
      quantity: 'Complete Module',
      checked: true,
      catg: "module",
      ref: "erp_enquiry"
    },
    {
      
      value: localPrcCardPackages && localPrcCardPackages.idcard > 0 ? localPrcCardPackages.idcard : '',
      label: 'ID Card Generation',
      quantity: 'Per Student',
      checked: true,
      catg: "stu",
      ref: "erp_idCard"
    },
    {
      
      value: localPrcCardPackages && localPrcCardPackages.selfAss > 0 ? localPrcCardPackages.selfAss : '',
      label: 'Self Assessment',
      quantity: 'Per Student',
      checked: true,
      catg: "stu",
      ref: "erp_selfAss"
    },
    {
      
      value: localPrcCardPackages && localPrcCardPackages.speakWell > 0 ? localPrcCardPackages.speakWell : '',
      label: 'Speakwell',
      quantity: 'Per Student',
      checked: true,
      catg: "stu",
      ref: "erp_speakWell"
    },
    {
      
      value: localPrcCardPackages && localPrcCardPackages.naipunya > 0 ? localPrcCardPackages.naipunya : '',
      label: 'Naipunya',
      quantity: 'Per Student',
      checked: true,
      catg: "stu",
      ref: "erp_naipunya"
    },
  ]

  const CouponList = [
    {
      value: 10,
      label: "GVCPNSA"
    },
    {
      value: 20,
      label: "GVCPNGK"
    },
    {
      value: 30,
      label: "GVCPNRP"
    },
    {
      value: 40,
      label: "GVCPNBP"
    },
    {
      value: 50,
      label: "GVCPNUY"
    },
  ]

  const priceCardPackages = [
    {
      label: "Base Pack",
      catg: "basepack",
      compPrice: 60,
      frchPrice: 60,
      type: "perStudent",
      validity: "perAnnum",
    },
    {
      label: "Attendance Plan",
      catg: "att",
      compPrice: 30,
      frchPrice: 0,
      type: "perStudent",
      validity: "perAnnum",
    },
    {
      label: "Lesson Plan",
      catg: "lessonPlan",
      compPrice: 20,
      frchPrice: 10,
      type: "perStudent",
      validity: "perAnnum",
    },
    {
      label: "Pay Roll (Per Employee)",
      catg: "payroll",
      compPrice: 80,
      frchPrice: 40,
      type: "perEmployee",
      validity: "perAnnum",
    },
    {
      label: "Multi-Color Tag with Holder (Student)",
      catg: "idTags_student",
      compPrice: 25,
      frchPrice: 5,
      type: "",
      validity: "",
    },
    {
      label: "Multi-Color Tag with Holder (Staff)",
      catg: "idTags_staff",
      compPrice: 30,
      frchPrice: 5,
      type: "",
      validity: "",
    },
    {
      label: "ID Card Generation",
      catg: "idCard",
      compPrice: 3,
      frchPrice: 2,
      type: "perCard",
      validity: "perAnnum",
    },
    // {
    //   label: "ID Card Printing (PVC Card)",
    //   catg: "idCardPrint_PVC",
    //   compPrice: 15,
    //   frchPrice: 0,
    //   type: "",
    //   validity: "",
    // },
    // {
    //   label: "ID Card Printing (RFID Card)",
    //   catg: "idCardPrint_RFID",
    //   compPrice: 25,
    //   frchPrice: 0,
    //   type: "",
    //   validity: "",
    // },
    {
      label: "Inventory Module (Per Branch)",
      catg: "invt",
      compPrice: 3000,
      frchPrice: 2000,
      type: "complete",
      validity: "perAnnum",
    },
    {
      label: "Enquiry Module (Per Branch)",
      catg: "enquiry",
      compPrice: 3000,
      frchPrice: 2000,
      type: "complete",
      validity: "perAnnum",
    },
    {
      label: "Self Assessment",
      catg: "selfAss",
      compPrice: 125,
      frchPrice: 25,
      type: "perStudent",
      validity: "perAnnum",
    },
    {
      label: "Speakwell",
      catg: "speakWell",
      compPrice: 200,
      frchPrice: 50,
      type: "perStudent",
      validity: "perAnnum",
    },
    {
      label: "Naipunya",
      catg: "naipunya",
      compPrice: 150,
      frchPrice: 50,
      type: "perStudent",
      validity: "perAnnum",
    },
  ];
  
  const generateInvoice = userDetails["generateInvoice"];
  const stuCount = userDetails["stuCount"];
  const empCount = userDetails["empCount"];
  const invCountMap = userDetails["invCountMap"]

  // Token Entry
  const PertainOptions = [
    { value: 0, label: "My Self" },
    { value: 1, label: "Employees" },
    { value: 2, label: "Vendors" },
    { value: 4, label: "Students" },
    { value: 5, label: "Marketing Sources" },
  ];
  const PriorityOptions = [
    { value: 1, label: "Low" },
    { value: 2, label: "Normal" },
    { value: 3, label: "High" },
    { value: 4, label: "Emergency" },
  ];
  const LevelOptions = [
    { value: 1, label: "Low" },
    { value: 2, label: "Normal" },
    { value: 3, label: "High" },
  ];
  const FormatOptions = [
    { value: 1, label: "Format_One" },
    { value: 2, label: "Format_Two" },
    { value: 3, label: "Format_Three" },
    { value: 4, label: "Format_Four" },
    { value: 5, label: "Format_Five" },
    { value: 6, label: "Format_Six" },
    { value: 7, label: "Format_Seven" },
    { value: 8, label: "Format_Eight" },
  ];
  const MarkTypes = [
    { value: "des", label: "Display by Exam Settings" },
    { value: "dm", label: "Display Marks" },
    { value: "dg", label: "Display Grades" }
  ];
  const TokenStatus = [
    { value: "initiated", label: "Initiated" },
    { value: "inprogress", label: "In-Progress" },
    { value: "resolved", label: "Resolved" },
    { value: "completed", label: "Completed" },
    { value: "requested", label: "Requested" },
    { value: "estimated", label: "Estimated" },
  ];
  const CurrentStatusOptions = [
    { value: 1, label: "Demo Completed and Data Received" },
    { value: 2, label: "Demo Completed and Data not Received" },
    { value: 3, label: "Demo Date Given" },
    { value: 4, label: "Data Received without Demo" },
    { value: 5, label: "Another Followup Required" },
    { value: 6, label: "Not Available" },
    { value: 7, label: "Not Interested" },
  ];
  const SmsTypeOptions = [
    { value: 1, label: "Transactional Route" },
    { value: 2, label: "Semi Transactional Route" },
    { value: 3, label: "Promotional Route" },
    { value: 4, label: "In / Out Sms" },
    { value: 5, label: "Home Work SMS" },
  ];
  //Naipunya Batch
  const NaipunyaBatches = [
    { value: -1, label: "Batch-01" },
    { value: -2, label: "Batch-02" },
    { value: -3, label: "Batch-03" },
    { value: -4, label: "Batch-04" },
    { value: -5, label: "Batch-05" },
    { value: -6, label: "Batch-06" },
    { value: -7, label: "Batch-07" },
    { value: -8, label: "Batch-08" },
    { value: -9, label: "Batch-09" },
    { value: -10, label: "Batch-10" },
  ];

  // exams crud
  const ExamTypes = [
    { value: 1, label: "Exam Only" },
    { value: 2, label: "Assignment and Exam" },
  ];
  //Work Update

  const WorkInterval = [
    { value: 0, label: "Daily" },
    { value: 1, label: "Weekly" },
    { value: 2, label: "Monthly" },
  ];
  const Timings = [
    { value: "9AM", label: "9:00AM" },
    { value: "10AM", label: "10:00AM" },
    { value: "11AM", label: "11:00AM" },
    { value: "12PM", label: "12:00PM" },
    { value: "1PM", label: "13:00PM" },
    { value: "2PM", label: "14:00PM" },
    { value: "3PM", label: "15:00PM" },
    { value: "4PM", label: "16:00PM" },
    { value: "5PM", label: "17:00PM" },
    { value: "6PM", label: "18:00PM" },
  ];

  // Board
  const BoardTypes = [
    { value: 1, label: "State Board" },
    { value: 2, label: "CBSE" },
    { value: 3, label: "ICSE" },
  ];
  // Board
  const AnlType = [
    { value: 'state', label: "State" },
    { value: 'cbse', label: "CBSE" },
  ];
  // Category
  const Category = [
    { value: "corp_sch", label: "Non - Corporate School" },
    { value: "school", label: "Corporate School" },
    { value: "tution", label: "Tution Point" },
    { value: "corp_clg", label: "Non - Corporate College" },
    { value: "college", label: "Corporate College" },
  ];

  // group
  const Group = [
    { value: "science", label: "SCIENCE" },
    { value: "arts", label: "ARTS" },
    { value: "commerce", label: "COMMERCE" },
    { value: "s&c", label: "SCIENCE & COMMERCE" },
    { value: "c&s", label: "COMMERCE & SCIENCE" },
  ];

  const GroupTypes = [
    { value: "FA", label: "FA" },
    { value: "SA", label: "SA" },
    { value: "PA", label: "PA" },
    { value: "OTHERS", label: "OTHERS" },
  ];
  const AttformatTypes = [
    { value: 1, label: "Month Wise" },
    { value: 2, label: "Overall" },
    { value: 3, label: "None" },
  ];

  // academic crud
  const currentyear = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];

  // Admission Year
  const admnYear = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];

  //OnlineExams
  const Level = [
    { value: 1, label: "Low" },
    { value: 2, label: "Medium" },
    { value: 3, label: "High" },
  ];
  const PaperDisplay = [
    { value: 1, label: "All Questions To One paper	" },
    { value: 2, label: "One by one" },
  ];
  const ResultType = [
    { value: 1, label: "Result announce at each Question" },
    { value: 2, label: "Result announce at end of exam" },
    { value: 3, label: "Result announce at Later" },
  ];
  const QuestionsSkipping = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];
  const TimePattern = [
    { value: 1, label: "BitWise Time" },
    { value: 2, label: "Whole Exam Time" },
  ];
  // banks crud
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];
  const studentStatusOptions = [
    { value: "active", label: "Active" },
    { value: "reserved", label: "Reserved" },
  ];
  const bankTypeOptions = [
    { value: 1, label: "Real Bank" },
    { value: 2, label: "Virtual Bank" },
    { value: 3, label: "Personal Bank" },
  ];

  const catgOptions = [
    { value: "Holiday(s)", label: "Holiday" },
    { value: "fee", label: "Fee" },
    { value: "spClasses", label: "Special Classes" },
    { value: "results", label: "Results" },
    { value: "exams", label: "Exams" },
    { value: "spAnnouncement", label: "Special Announcement" },
];

  // approval seetings crud
  const approvalTypes = [
    { value: "exp", label: "Expenses" },
    { value: "conc", label: "Concession" },
    { value: "budget", label: "Budget" },
    { value: "bill", label: "Bills" },
    { value: "trfr", label: "Finance Transfers" },
    { value: "leave", label: "Leaves" },
    { value: "exem", label: "Attendance Exemptions" },
    { value: "own", label: "Own Consumption" },
    { value: "emp", label: "Employee Approval" },
  ];
  const approvalLevels = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
  ];

  // account type crud
  const accountType = [
    { value: 1, label: "INCOME" },
    { value: 2, label: "EXPENSE" },
    { value: 3, label: "ASSETS" },
    { value: 4, label: "LIABILITIES" },
    { value: 5, label: "INCOME+EXPENSE" },
    { value: 6, label: "NON-RECURING EXPENSE" },
  ];

  const AccCategories = [
    { value: 'salary', label: "Salary"},
    { value: 'rent', label: "Rent"},
    { value: 'diesel', label: "Diesel"},
    { value: 'emi', label: "EMI"},
    { value: 'maintenance', label: "Maintenance"},
    { value: 'books', label: "Books"},
    { value: 'uniform', label: "Uniform"},
    { value: 'others', label: "Others"},
  ];

  // Fee Category crud
  const FeeCategory = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];

  // Add Inventory crud
  const Dashboard = [
    { value: true, label: "true" },
    { value: false, label: "false" },
  ];
  const Measure = [
    { value: "Count", label: "Count" },
    { value: "Liters", label: "Liters" },
    { value: "KG", label: "KG" },
    { value: "Meters", label: "Meters" },
  ];

  // Fee Head crud
  const Terms = [
    { value: 1, label: "1 Term" },
    { value: 2, label: "2 Terms" },
    { value: 3, label: "3 Terms" },
    { value: 4, label: "4 Terms" },
    { value: 5, label: "5 Terms" },
    { value: 6, label: "6 Terms" },
    { value: 7, label: "7 Terms" },
    { value: 8, label: "8 Terms" },
    { value: 9, label: "9 Terms" },
    { value: 10, label: "10 Terms" },
    { value: 11, label: "11 Terms" },
    { value: 12, label: "12 Terms" },
  ];
  const HideOption = [
    { value: true, label: "true" },
    { value: false, label: "false" },
  ];
  const ApplicableTo = [
    { value: true, label: "New Admissions only" },
    { value: false, label: "All Students" },
  ];

  const FeeType = [
    { value: "total", label: "All" },
    { value: "tuition", label: "Tuition" },
    { value: "trpt", label: "Bus" },
    { value: "hstl", label: "Hostel" },
    { value: "others", label: "Others" },
  ];
  const EditStd = [
    { value: true, label: "Yes" },
    { value: false, label: "NO" },
  ];
  const SpecialCourse = [
    { value: 1, label: "Yes" },
    { value: 2, label: "NO" },
  ];

  // Hall Tickets
  const studentTypes = [
    { value: "Hostelers", label: "Hostelers" },
    { value: "NonHostelers", label: "NonHostelers" },
    { value: "Both", label: "Both" },
  ];
  const selectionDetails = [
    { value: "All Students", label: "All Students" },
    { value: "Single Student", label: "Single Student" },
  ];
  const feeTypes = [
    { value: "All", label: "All" },
    { value: "Full Paid", label: "Full Paid" },
    { value: "Full Paid / Partial Paid", label: "Full Paid / Partial Paid" },
    { value: "Partial Paid / UnPaid", label: "Partial Paid / UnPaid" },
    { value: "UnPaid", label: "UnPaid" },
  ];
  const HtfeeTypes = [
    { value: "all", label: "All" },
    { value: "fullPaid", label: "Full Paid" },
    { value: "unPaid", label: "UnPaid" },
    { value: "partialPaid", label: "Partial Paid" },
  ];
  const feeTerms = [
    { value: 1, label: "Term - 1" },
    { value: 2, label: "Term - 2" },
    { value: 3, label: "Term - 3" },
    { value: 4, label: "Term - 4" },
    { value: 5, label: "Term - 5" },
    { value: 6, label: "Term - 6" },
    { value: 7, label: "Term - 7" },
    { value: 8, label: "Term - 8" },
    { value: 9, label: "Term - 9" },
    { value: 10, label: "Term - 10" },
    { value: 11, label: "Term - 11" },
    { value: 12, label: "Term - 12" },
  ];
  const displayOptions = [
    { value: "rollNo", label: "Roll No" },
    { value: "fName", label: "Father Name" },
    { value: "admNo", label: "Adm No" },
    { value: "clsT", label: "Class Teacher" },
    { value: "applFee", label: "Applicable Fee" },
    { value: "paidFee", label: "Paid Fee" },
    { value: "feeDue", label: "Fee Due" },
    { value: "instructions", label: "Instructions" },
    { value: "passMaxMark", label: "Pass Mark / Max Mark" },
    { value: "time", label: "Exam Time" },
  ];
  // const displayOptions = [
  //   { value: "2", label: "Roll No" },
  //   { value: "3", label: "Father Name" },
  //   { value: "Wedn", label: "Adm No" },
  //   { value: "Thursday", label: "Thursday" },
  //   { value: "Friday", label: "Friday" },
  //   { value: "paidFee", label: "Paid Fee" },
  //   { value: "feeDue", label: "Fee Due" },
  //   { value: "instructions", label: "Instructions" },
  //   { value: "passMaxMark", label: "Pass Mark / Max Mark" },
  // ];
  const signatures = [
    { value: "clsTSign", label: "Class Teacher Signature" },
    { value: "adminSign", label: "System Admin Signature" },
    { value: "header", label: "Progress Banner as Heading" },
  ];
  const scansignatures = [
    {
      value: "PrincWS",
      label: "Principal label with scanned sign",
    },
    {
      value: "PrincWOS",
      label: "Principal label without scanned sign",
    },
  ];

  // Cbsc pattern
  const term = [
    { value: -1, label: 1 },
    { value: -2, label: 2 },
    { value: -3, label: "Final" },
  ];
  const subjectgroups = [
    { value: "FA", label: "FA" },
    { value: "SA", label: "SA" },
    { value: "PA", label: "PA" },
  ];
  const subjGroups = [
    { value: "MT", label: "MT" },
    { value: "PF", label: "PF" },
    { value: "SEA", label: "SEA" },
    { value: "PT", label: "PT" },
  ];
  const termList = [
    { value: -4, label: "Part 1" },
    { value: -5, label: "Part 2" },
    { value: -6, label: "Term1 (Cons)" },
    { value: -7, label: "Part 3" },
    { value: -8, label: "Part 4" },
    { value: -9, label: "Term2 (Cons)" },
    { value: -10, label: "Final 1" },
    { value: -11, label: "Final 2" },
  ];

  //Cbsc Groups
  const part = [
    { value: 0, label: "Not Specified" },
    { value: 1, label: "Academic Perfomance:Scholastis Areas" },
    { value: 2, label: "Co-Scholastis Areas" },
    { value: 3, label: "Co-Scholastis Activities" },
  ];
  //Institute Timings
  const Day = [
    { value: 1, label: "Sunday" },
    { value: 2, label: "Monday" },
    { value: 3, label: "Tuesday" },
    { value: 4, label: "Wednesday" },
    { value: 5, label: "Thursday" },
    { value: 6, label: "Friday" },
    { value: 7, label: "Saturday" },
  ];

  const PhotoSessAllowDaysOptions = [
    { value: "1", label: "Sunday" },
    { value: "2", label: "Monday" },
    { value: "3", label: "Tuesday" },
    { value: "4", label: "Wednesday" },
    { value: "5", label: "Thursday" },
    { value: "6", label: "Friday" },
    { value: "7", label: "Saturday" },
  ];
  const genderOptions = [
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
  ];
  const certOptions = [
    { value: 1, label: "Submitted" },
    { value: 2, label: "Not Submitted" },
  ];
  // student admission / reservation form
  const stustatusOptions = [
    { value: "active", label: "Admission" },
    { value: "reserved", label: "Reservation" },
  ];
  const admissionOptions = [
    { value: 1, label: "Staff" },
    // { value: 2, label: "Pro" },
    { value: 3, label: "Reception" },
    { value: 4, label: "Others" },
  ];
  const facilityOptions = [
    { value: 1, values : true, label: "Using" },
    { value: 0, values : false, label: "Not Using" },
  ];

  const trptOptions = [
    { value: 0, values : false, label: "Not Using" },
    { value: 1, values : true, label: "Both Pickup & Dropping" },
    { value: 2, values : true, label: "Pickup Only" },
    { value: 3, values : true, label: "Dropping Only" },
  ];

  const fathusOptions = [
    { value: 1, label: "Father" },
    { value: 2, label: "Husband" },
  ];
  const jobRoleOptions = [
    { value: 1, label: "Part Year" },
    { value: 2, label: "Full Year" },
  ];
  const bloodgroupOptions = [
    { value: 1, label: "O+ve" },
    { value: 2, label: "O-ve" },
    { value: 3, label: "A+ve" },
    { value: 4, label: "A-ve" },
    { value: 5, label: "AB+ve" },
    { value: 6, label: "AB-ve" },
    { value: 7, label: "B+ve" },
    { value: 8, label: "B-ve" },
    { value: 9, label: "A1+ve" },
    { value: 10, label: "A1-ve" },
    { value: 11, label: "A1B+ve" },
    { value: 12, label: "A1B-ve" },
    { value: 13, label: "A2+ve" },
    { value: 14, label: "A2-ve" },
    { value: 15, label: "A1RH+ve" },
    { value: 16, label: "A1RH-ve" },
    { value: 17, label: "A2B+ve" },
    { value: 18, label: "A2B-ve" },
    { value: 19, label: "ABRH+ve" },
  ];
  const casteOptions = [
    { value: 'general', label: "General"},
    { value: 'oc', label: "OC"},
    { value: 'obc', label: "OBC"},
    { value: 'bc', label: "BC"},
    { value: 'mbc', label: "MBC"},
    { value: 'dnc', label: "DNC"},
    { value: 'gt', label: "GT"},
    { value: 'sc', label: "SC"},
    { value: 'st', label: "ST"},
    { value: 'sca', label: "SCA"},
    { value: 'oec', label: "OEC"},
    { value: 'bcm', label: "BCM"},
  ];
  const applicableOptions = [
    { value: 1, label: "April" },
    { value: 2, label: "May" },
    { value: 3, label: "June" },
    { value: 4, label: "July" },
    { value: 5, label: "August" },
    { value: 6, label: "September" },
    { value: 7, label: "October" },
    { value: 8, label: "November" },
    { value: 9, label: "December" },
    { value: 10, label: "January" },
    { value: 11, label: "February" },
    { value: 12, label: "March" },
  ];
  const maritalStatusOptions = [
    { value: "married", label: "Married" },
    { value: "unmarried", label: "UnMarried" },
  ];
  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const clubOptions = [
    { value: 1, label: "G-1" },
    { value: 2, label: "G-2" },
    { value: 3, label: "G-3" },
    { value: 4, label: "G-4" },
    { value: 5, label: "G-5" },
    { value: 6, label: "G-6" },
    { value: 7, label: "B-1" },
    { value: 8, label: "B-2" },
    { value: 9, label: "B-3" },
    { value: 10, label: "B-4" },
    { value: 11, label: "B-5" },
    { value: 12, label: "B-6" },
  ];

  // student activities
  const stdType = [
    { value: 1, label: "Group Discussion" },
    { value: 2, label: "Jam" },
    { value: 3, label: "Exam" },
  ];
  // student Enquiry
  const typeOfEnq = [
    { value: 1, label: "Admission Followup" },
    { value: 2, label: "Registation Form" },
    { value: 3, label: "Exam Application" },
  ];
  const enqType = [
    { value: 1, label: "Personal" },
    { value: 2, label: "Phone" },
    { value: 3, label: "TV Ads/Media" },
    // { value: 4, label: "PRO" },
    { value: 5, label: "Old Student Reference" },
    { value: 6, label: "Staff/Tele-Caller Reference" },
    { value: 7, label: "Digital Marketing" },
    { value: 8, label: "SMS" },
    { value: 9, label: "Others" },
  ];
  const dayOrRes = [
    { value: 1, label: "Day" },
    { value: 2, label: "Resendial" },
  ];
  const statusTypes = [
    { value: 1, label: "Interested" },
    { value: 2, label: "Not Interested" },
    { value: 3, label: "Followup" },
    { value: 4, label: "Application" },
  ];

  const enqStatusTypes = [
    { value: 1, label: "Not Yet Started" },
    { value: 2, label: "Decided to Other School" },
    { value: 3, label: "Not Interested" },
    { value: 4, label: "Interested" },
    { value: 6, label: "Application" },
    { value: 5, label: "Admission" },
  ];

  const MrkSrcEnqStatusTypes = [
    { value: 1, label: "Not Yet Started" },
    { value: 2, label: "Not Interested" },
    { value: 3, label: "Follow Up" },
    { value: 4, label: "Demo" },
    { value: 5, label: "Onsite" },
    { value: 6, label: "Finalization" },
    { value: 7, label: "Agreement" },
  ];

  const reasonTypes = [
    { value: 1, label: "Fee Concern" },
    { value: 2, label: "Infrastructure" },
    { value: 3, label: "Transport Issue" },
    { value: 4, label: "Communication" },
    { value: 5, label: "Syallbus" },
    { value: 6, label: "Discipline" },
    { value: 7, label: "Faculty Dedication" },
    { value: 8, label: "Others" },
  ];
  const payModes = [
    { value: "online", label: "Online / Challan" },
    { value: "cheque", label: "Cheque / DD" },
    { value: "cash", label: "Cash Payments" },
    { value: "swiping", label: "Card Swiping" },
  ];
  // results progress card by exam
  const resultexamTypes = [
    { value: 1, label: "By Exam" },
    { value: 2, label: "By Group" },
    { value: 3, label: "By Term" },
    { value: 4, label: "Annual Report" },
  ];
  const resultterms = [
    { value: -1, label: "Term1" },
    { value: -2, label: "Term2" },
    { value: -3, label: "Term3" },
  ];
  const progressterms = [
    { value: 1, label: "Term 1" },
    { value: 2, label: "Term 2" },
  ];
  const resultorderBy = [
    { value: "gender", label: "Gender & Name" },
    { value: "regNo", label: "Registration No" },
  ];
  const resultperOptions = [
    { value: "admNo", label: "Adm No" },
    { value: "rollNo", label: "Roll No" },
    { value: "fatherName", label: "Father Name" },
    { value: "dob", label: "Date of Birth" },
  ];
  const resultdisOptions = [
    { value: "maxMark", label: "Subject Wise Max Mark" },
    { value: "secureMarks", label: "Secured Marks" },
    { value: "subjGrade", label: "Subject Wise Grade" },
    { value: "subjPoints", label: "Subject Wise Points" },
    { value: "clsHigh", label: "Class Highest" },
    { value: "clsAvg", label: "Class Average" },
    { value: "grdTbl", label: "Grades Table" },
    { value: "princSign", label: "Principal Signature" },
    { value: "rank", label: "Rank Display" },
    { value: "remrk", label: "Remark" },
    { value: "teacherComment", label: "Teacher Comment" },
    { value: "att", label: "Attendance" },
    { value: "out100", label: "Out of 100" },
  ];
  //Year Options
  const yearOptions = [
    { value: "2025", label: "2025" },
    { value: "2024", label: "2024" },
    { value: "2023", label: "2023" },
    { value: "2022", label: "2022" },
    { value: "2021", label: "2021" },
    { value: "2020", label: "2020" },
    { value: "2019", label: "2019" },
    { value: "2018", label: "2018" },
    { value: "2017", label: "2017" },
    { value: "2016", label: "2016" },
    { value: "2015", label: "2015" },
    { value: "2014", label: "2014" },
    { value: "2013", label: "2013" },
  ];
  //academic year options
  const academicOptions = [
    { value: "2022-2023", label: "2022 - 2023" },
    { value: "2021-2022", label: "2021 - 2022" },
    { value: "2020-2021", label: "2020 - 2021" },
    { value: "2019-2020", labe: "2019 - 2020" },
  ];
  const academicYearOptions = [
    { value: "2024-2025", label: "2024 - 2025" },
    { value: "2023-2024", label: "2023 - 2024" },
    { value: "2022-2023", label: "2022 - 2023" },
    { value: "2021-2022", label: "2021 - 2022" },
    { value: "2020-2021", label: "2020 - 2021" },
    { value: "2019-2020", label: "2019 - 2020" },
    { value: "2018-2019", label: "2018 - 2019" },
    { value: "2017-2018", label: "2017 - 2018" },
    { value: "2016-2017", label: "2016 - 2017" },
    { value: "2015-2016", label: "2015 - 2016" },
    { value: "2014-2015", label: "2014 - 2015" },
    { value: "2013-2014", label: "2013 - 2014" },
    { value: "2012-2013", label: "2012 - 2013" },
  ];

  //Leave Types
  const carry = [
    { value: 1, label: "yes" },
    { value: 2, label: "No" },
  ];
  const prohibitionApply = [
    { value: 1, label: "yes" },
    { value: 2, label: "No" },
  ];
  //Holidayes List
  const holidayType = [
    { value: "Regular", label: "regular" },
    { value: "Flexible", label: "flexible" },
  ];
  //add buses
  const warenty = [
    { value: true, label: "Yes" },
    { value: false, label: "NO" },
  ];
  const vachileType = [
    { value: "passenger", label: "Passenger" },
    { value: "goods", label: "Goods" },
  ];
  //Cbsc Subjects
  const descInd = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];
  const grade = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];

  //Emplyes information add
  const teachType = [
    { value: 1, label: "Teaching" },
    { value: 2, label: "Non-Teaching" },
    { value: 3, label: "Technical" },
    { value: 4, label: "Office" },
    { value: 5, label: "Class-IV" },
    { value: 6, label: "TOP Management" },
    { value: 7, label: "Marketing" },
  ];
  const commType = [
    { value: 1, label: "SMS" },
    { value: 2, label: "Notices" },
  ];
  const pastDate = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];
  const phNoDisp = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];
  const stuEdit = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];
  const billToOptions = [
    { value: 1, label: "Vendors" },
    { value: 2, label: "Employees" },
    // { value: 3, label: "Salaries" },
  ];

  // finance transfers
  const transfers = [
    { value: 1, label: "Deposit" },
    { value: 2, label: "Withdraw" },
    { value: 3, label: "Bank Transfer" },
    { value: 4, label: "Other Bank Transfer" },
    { value: 5, label: "Branch Transfer" },
  ];

  // expesnes entry
  const exppayModes = [
    { value: "cash", label: "Cash Payments" },
    { value: "online", label: "Online Payments" },
    { value: "cheque", label: "Cheque/DD Payments" },
  ];
  const exppaydialyModes = [
    { value: "cash", label: "Cash Payments" },
    { value: "online", label: "Online Payments" },
    { value: "cheque", label: "Cheque/DD Payments" },
    { value: "swiping", label: "Card Swiping" },
  ];
  const expPaidTo = [
    { value: "VEND", label: "Vendors" },
    { value: "EMP", label: "All Employees" },
    { value: "OTHERS", label: "Others" },
    { value: "STU", label: "Students" },
    { value: "COMP", label: "Company" },
    // { value: "BLOCK", label: "Units" },
    { value: "EMPS", label: "Current Employees" },
  ];
  const sources = [
    { value: "VEND", label: "Vendors" },
    { value: "EMP", label: "All Employees" },
    { value: "OTHERS", label: "Others" },
  ];

  //employee advances
  const montesName = [
    { value: 1, label: "Jan" },
    { value: 2, label: "Feb" },
    { value: 3, label: "Mar" },
    { value: 4, label: "Apr" },
    { value: 5, label: "May" },
    { value: 6, label: "Jun" },
    { value: 7, label: "Jul" },
    { value: 8, label: "Aug" },
    { value: 9, label: "Sep" },
    { value: 10, label: "Oct" },
    { value: 11, label: "Nov" },
    { value: 12, label: "Dec" },
  ];

  //employee designation
  const designationType = [
    { value: 1, label: "Teaching" },
    { value: 2, label: "Non-Teaching" },
    { value: 3, label: "Administrator" },
  ];

  //income collection
  const income = [
    { value: 1, label: "Admited" },
    { value: 2, label: "Reserved" },
    { value: 3, label: "Old" },
    { value: 4, label: "Detained" },
  ];
  const stdStatus = [
    { value: "all", label: "All" },
    { value: "active", label: "Admitted" },
    { value: "reserved", label: "Reserved" },
    { value: "inactive", label: "Old/In-Active" },
    { value: "detained", label: "Detained" },
  ];
  const incomeFee = [
    { value: 1, label: "Student Fee" },
    { value: 2, label: "Other Income" },
  ];
  const source = [
    { value: "VEND", label: "Vendors" },
    { value: "EMP", label: "All Employees" },
    { value: "OTHERS", label: "Others" },
  ];
  const incomepayModes = [
    { value: "cash", label: "Cash Payments" },
    { value: "online", label: "Online Payments" },
    { value: "cheque", label: "Cheque/DD Payments" },
    // { value: "upi", label: "UPI Payments" },
    { value: "swiping", label: "Card Swiping" },
    // { value: "cheque_cash", label: "Cheque & Cash Combination" },
    // { value: "swiping_cash", label: "Swiping & Cash Combination" },
  ];
  const incomepayMode = [
    { value: "cash", label: "Cash Payments" },
    { value: "online", label: "Online Payments" },
  ];
  const accountTypes = [
    { value: "empSal", label: "Employee Salary" },
    { value: "otherIncome", label: "Other Income" },
    { value: "vendors", label: "Vendors" },
    { value: "operation on vendors", label: "Operation on Vendors" },
    { value: "safetySkills", label: "Safety Skills" },
    { value: "expenses", label: "Expenses" },
  ];
  const subAccountNames = [
    { value: "anilpp", label: "Anilpp" },
    { value: "dtjtytj", label: "dtjtytj" },
  ];
  const InnerSubAccountNamesOne = [{ value: "anilpp", label: "Anilpp" }];
  const InnerSubAccountNamesTwo = [{ value: "dtjtytj", label: "dtjtytj" }];
  const ptOptions = [
    { value: 1, label: "Applicable" },
    { value: 2, label: "Not Applicable" },
  ];
  const attMode = [
    { value: 1, label: "Double Punch" },
    { value: 2, label: "Single Punch" },
  ];
  const payrollType = [
    { value: 1, label: "Without PF & ESI" },
    { value: 2, label: "With Fixed PF & ESI" },
    { value: 3, label: "With Variable PF & ESI" },
  ];
  const salPayMode = [
    { value: 1, label: "Cheque" },
    { value: 2, label: "online" },
    { value: 3, label: "OD" },
  ];
  // Description Options
  const DescOptions = [
    { value: "1", label: "FACE DETECTION AND BIOMETRIC" },
    { value: "2", label: "ADMISSION & ASSESSMENT SOFTWARE" },
    { value: "3", label: "BULK SMS" },
    { value: "4", label: "SMART ID CARD (STUDENT)" },
    { value: "5", label: "NORMAL ID CARD (STUDENT)" },
    { value: "6", label: "SMART ID CARD (STAFF)" },
    { value: "7", label: "NORMAL ID CARD (STAFF)" },
    { value: "8", label: "MULTI-COLOR ID TAG WITH HOLDER" },
    { value: "9", label: "GPS DEVICE" },
    { value: "10", label: "MOBILE APP" },
    { value: "11", label: "WEBSITE DESIGN & HOSTING" },
    { value: "12", label: "COURIER CHARGES" },
    { value: "others", label: "OTHERS" },
  ];
  //Invoice Category Options
  const invCatgOpt = [
    { value: "erp", label: "ERP CHARGES" },
    { value: "rfid_cards", label: "RFID ID CARDS" },
    { value: "normal_cards", label: "NORMAL ID CARDS" },
    { value: "id_tags", label: "ID TAGS" },
    { value: "sms", label: "PAID SMS" },
    { value: "gps_device", label: "GPS DEVICE" },
    { value: "reader", label: "READERS" },
    { value: "design", label: "WEB DESIGN/HOSTING" },
    { value: "outSrc", label: "OUTSOURCING" },
    { value: "consg", label: "COURIER CHARGES" },
  ];
  // ButtonStage Options
  const paperTypeOpt = [
    { value: "regCertificate", label: "Registration Certificate" },
    { value: "Insurance", label: "Insurance" },
    { value: "Permit", label: "Permit" },
    { value: "Pollution", label: "Pollution" },
    { value: "Road Tax", label: "Road Tax" },
    { value: "fitnessCertificate", label: "Fitness Certificate" },
    { value: "Battery", label: "Battery" },
    { value: "Engine Oil", label: "Engine Oil" },
    { value: "Alignment", label: "Alignment" },
    { value: "Filters", label: "Filters" },
    { value: "Greeze", label: "Greeze" },
  ];
  // ButtonStage Options
  const buttonStageOpt = [
    { value: "25", label: "25" },
    { value: "50", label: "50" },
    { value: "75", label: "75" },
    { value: "100", label: "100" },
  ];
  const tyreStatusOpt = [
    { value: "Running", label: "Running" },
    { value: "Spare", label: "Spare" },
    { value: "Closed", label: "Closed" },
  ];
  const tyreConditionOpt = [
    { value: "New", label: "New" },
    { value: "ReButton", label: "ReButton" },
  ];
  const tyreSizeOpt = [
    { value: "AAA", label: "AAA" },
    { value: "BBB", label: "BBB" },
    { value: "CCC", label: "CCC" },
  ];
  const actionOpt = [
    { value: "purchase", label: "Purchase" },
    { value: "sale", label: "Sale" },
    { value: "transfer", label: "Transfer" },
    { value: "consumption", label: "Consumption" },
  ];
  const typeOpt = [
    { value: "1", label: "Asset" },
    { value: "2", label: "Stock" },
  ];

  const questionOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];
  const questionLevels = [
    { value: 1, label: "Low" },
    { value: 2, label: "Normal" },
    { value: 3, label: "High" },
  ];

  //company Add
  const companyTypeOpt = [
    { value: "op", label: "OP" },
    { value: "grp", label: "Group" },
    { value: "alliance", label: "Alliance" },
    { value: "const", label: "Construction" },
    { value: "association", label: "Association" },
    { value: "panchayat", label: "Panchayat" },
    { value: "retail", label: "Retail" },
    { value: "gold", label: "GoldSmith" },
    { value: "hosp", label: "Hospital" },
    { value: "gaitview", label: "Gait View" },
    { value: "sms", label: "Bulk Sms" },
    { value: "sch", label: "School" },
    { value: "hotel", label: "Hotel" },
    { value: "payroll", label: "Payroll" },
  ];
  const byReferenceOpt = [
    { value: 1, label: "By School" },
    { value: 2, label: "Other" },
    { value: 3, label: "None" },
  ];
  const collectModeOpt = [
    { value: 'branch', label: "By Branch" },
    { value: 'finGrp', label: "By Group" },
    { value: 'alliance', label: "By Alliance" },
  ];
  const attendanceAlertOpt = [
    { value: 1, label: "Entry" },
    { value: 2, label: "Exit" },
    { value: 3, label: "Entry&Exit" },
    { value: 4, label: "Late Come" },
    { value: 5, label: "Early Go" },
    { value: 6, label: "Late Come & Early Go" },
  ];

  const autoAbsents = [
    { value: "active", label: "Using" },
    { value: "inactive", label: "Not Using" },
  ];

  const MifareUHFOptions = [
    { value: 1, label: "Not-Using" },
    { value: 2, label: "Mifare" },
    { value: 3, label: "UHF" },
  ];
  const StudentsProgress = [
    { value: 1, label: "Failed" },
    { value: 2, label: "Not Failed" },
  ];

  const StudentConduct = [
    { value: 1, label: "Good" },
    { value: 2, label: "Very Good" },
    { value: 3, label: "Satisfactory" },
    { value: 4, label: "Bad" },
  ];

  const ReasonForLeaving = [
    { value: 1, label: "Personal" },
    { value: 2, label: "For Further Study" },
    { value: 3, label: "Parents Request" },
    { value: 4, label: "Parents Transfer" },
    { value: 5, label: "Course Complete" },
    { value: 6, label: "Completion of Class 12" },
    { value: 7, label: "Discontinued" },
  ];

  const StudentPromoted = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  // employee overview
  const periodOptions = [
    { value: 0, label: "All" },
    { value: 1, label: "Part Year" },
    { value: 2, label: "Full Year" },
  ];
  const genOptions = [
    { value: 0, label: "All" },
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
  ];
  const dateSelections = [
    { value: 0, label: "Active Employees" },
    { value: 1, label: "Joined Between Dates" },
    { value: 2, label: "Relieve Between Dates" },
  ];
  const expRange = [
    { value: 0, label: "0 - 6 Months" },
    { value: 1, label: "6 Months - 1 Year" },
    { value: 2, label: "1 Year - 3 Years" },
    { value: 3, label: "3 Years - 5 Years" },
    { value: 4, label: "5+ Years" },
  ];
  const salRange = [
    { value: 0, label: "0 - 9,999" },
    { value: 1, label: "10,000 to 19,999" },
    { value: 2, label: "20,000 to 29,999" },
    { value: 3, label: "30,000 to 39,9999" },
    { value: 4, label: "40,000 to 49,9999" },
    { value: 5, label: "50,000+" },
  ];
  const rowSelection = [
    { value: 1, label: "Branch" },
    { value: 2, label: "Area" },
    { value: 3, label: "Department" },
    { value: 4, label: "Experience Range" },
    { value: 5, label: "Salary Range" },
    { value: 6, label: "Designations" },
  ];
  const columnSelection = [
    { value: 1, label: "Area" },
    { value: 2, label: "Department" },
    { value: 3, label: "Experience Range" },
    { value: 4, label: "Salary Range" },
  ];

  const FieldTypeOptions = [
    { value: 1, label: "Image" },
    { value: 2, label: "Bar Code" },
    { value: 3, label: "Static Variable" },
    { value: 4, label: "Dynamic Variable" },
  ];

  const HorzVertOptions = [
    { value : 1, label : "Horizontal"},
    { value : 2, label : "Vertical"}
  ]

  const ShadowDirectionOptions = [
    { value : 1, label : "Top"},
    { value : 2, label : "Right"},
    { value : 3, label : "Bottom"},
    { value : 4, label : "Left"},
  ]

  const FontTypeOptions = [
    { value : 1, label : "Arial"},
    { value : 2, label : "Verdana"},
    { value : 3, label : "Times New Roman"},
    { value : 4, label : "Anastasia"},
    { value : 5, label : "Tw Cen MT"},
    { value : 6, label : "Edwardian Script ITC"},
    { value : 7, label : "Segoe Script"},
    { value : 8, label : "Bell MT"},
    { value : 9, label : "Monda"},
  ]

  const FontAlignOptions = [
    { value : 1, label : "Center"},
    { value : 2, label : "Left"},
    { value : 3, label : "Right"},
    { value : 4, label : "Justify"}
  ]

  const FontWeightOptions = [
    { value : 0, label : "Normal"},
    { value : 1, label : "Bold"},
    { value : 2, label : "Italic"},
  ]

  return {
    periodOptions,
    genOptions,
    dateSelections,
    expRange,
    salRange,
    rowSelection,
    columnSelection,
    questionOptions,
    questionLevels,
    sources,
    ptOptions,
    attMode,
    payrollType,
    salPayMode,
    errorresponse,
    subAccountNames,
    InnerSubAccountNamesOne,
    InnerSubAccountNamesTwo,
    apprSettings,
    imsApproval,
    bioCode,
    billToOptions,
    accountTypes,
    ip,
    uid,
    pwd,
    stuSno,
    stuBusNo,
    stuFeeCallDate,
    SubBranchList,
    SchBranchList,
    SchIdList,
    GrpBranchList,
    InvCompDtls,
    invId,
    Banks,
    BranchIdList,
    defaultYear,
    dashboards,
    siblingList,
    SiblingDataMap,
    LoginTime,
    logType,
    monthnames,
    monthfullnames,
    empName,
    desgRef,
    companyId,
    companyType,
    grpId,
    companyName,
    rmId,
    senderId,
    companyAddr,
    companyCity,
    companyMail,
    companyMobNo,
    gstNo,
    branchName,
    gpsUname,
    gpsPwd,
    aliId,
    paymentAlert,
    concsLimit,
    keyPplList,
    keyPplDtlList,
    RmDetails,
    PaymentDetails,
    empLastName,
    empCompId,
    empPastDate,
    empAttExemLimit,
    empDept,
    swMentor,
    naipunyaMentor,
    phoneNoDisplay,
    naipunyaStuSubjList,
    SubBranchName,
    employeeId,
    finGrp,
    idStatus,
    acadGrp,
    authAxios,
    authAxiosMulti,
    authAxiosAtom,
    DateTimeFormat,
    DateFormatMonth,
    date,
    CurrentTime,
    DateFormat,
    DateOrderFormat,
    phoneNumberMask,
    OTPMask,
    ImgFormat,
    TimeFormat,
    TimeFormatNotSec,
    AmountFormat,
    AmountFormat2Digits,
    AmtFormat,
    AmtDecFormat,
    MarksFormat,
    MarkFormat,
    NAmtFormat,
    NumToWords,
    convertDateToWords,
    convertNumberToWords,
    sorter,
    amtSymbol,
    Classes,
    Sections,
    Designations,
    findDesignation,
    Mediums,
    Degrees,
    DeptCls,
    // BranchList,
    // DeptList,
    // ClsList,
    // SecList,
    ClassTeacher,
    DiaryAlert,
    Departments,
    AcademicYear,
    curDefultYear,
    curDefultYearCode,
    InvYear,
    GVInvYear,
    Branches,
    GrpBranches,
    Electives,
    ExamTypes,
    BoardTypes,
    AnlType,
    Category,
    Group,
    GroupTypes,
    AttformatTypes,
    currentyear,
    admnYear,
    Level,
    PaperDisplay,
    ResultType,
    QuestionsSkipping,
    TimePattern,
    statusOptions,
    bankTypeOptions,
    catgOptions,
    approvalTypes,
    approvalLevels,
    accountType,
    AccCategories,
    subjectsArr,
    FeeCategory,
    Dashboard,
    Measure,
    Terms,
    HideOption,
    ApplicableTo,
    FeeType,
    EditStd,
    SpecialCourse,
    studentTypes,
    selectionDetails,
    feeTypes,
    HtfeeTypes,
    feeTerms,
    displayOptions,
    signatures,
    scansignatures,
    stustatusOptions,
    enqStatusTypes,
    MrkSrcEnqStatusTypes,
    reasonTypes,
    genderOptions,
    PhotoSessAllowDaysOptions,
    certOptions,
    admissionOptions,
    facilityOptions,
    trptOptions,
    bloodgroupOptions,
    maritalStatusOptions,
    casteOptions,
    fathusOptions,
    jobRoleOptions,
    applicableOptions,
    clubOptions,
    monthOptions,
    numberOptions,
    numOptions,
    attendenceOptions,
    loginOptions,
    instituteOptions,
    authTypeOptions,
    term,
    subjectgroups,
    subjGroups,
    resultexamTypes,
    resultterms,
    progressterms,
    resultorderBy,
    resultperOptions,
    resultdisOptions,
    termList,
    part,
    stdType,
    typeOfEnq,
    enqType,
    dayOrRes,
    payModes,
    statusTypes,
    Day,
    carry,
    prohibitionApply,
    holidayType,
    warenty,
    WorkInterval,
    Timings,
    vachileType,
    descInd,
    grade,
    teachType,
    commType,
    pastDate,
    phNoDisp,
    stuEdit,
    transfers,
    exppayModes,
    exppaydialyModes,
    expPaidTo,
    montesName,
    income,
    designationType,
    stdStatus,
    incomeFee,
    source,
    incomepayModes,
    incomepayMode,
    empSeries,
    localPrcCardPackages,
    basePack,
    attPack,
    incomeRecords,
    expenseRecords,
    examRecords,
    PackagesList,
    CouponList,
    priceCardPackages,
    generateInvoice,
    stuCount,
    empCount,
    invCountMap,
    PertainOptions,
    PriorityOptions,
    LevelOptions,
    FormatOptions,
    MarkTypes,
    TokenStatus,
    CurrentStatusOptions,
    SmsTypeOptions,
    NaipunyaBatches,
    StuBean,
    CollectMode,
    CompanyBean,
    feedbackMandate,
    feedbackCollection,
    Grades,
    RdvHSGrades,
    MontfortGrades,
    yearOptions,
    academicOptions,
    studentStatusOptions,
    academicYearOptions,
    stuAtt,
    applFee,
    printType,
    partCollect,
    pocketInLedger,
    recOfcCopy,
    dueInRec,
    prFrmt,
    tokenReportTo,
    lateComesPerLeave,
    leadBy,
    url,
    instType,
    empAtt,
    stuDashboardOrder,
    lastLogin,
    resolvedToken,
    discountStartDate,
    discountEndDate,
    discountPerc,
    feeSmsCheck,
    signShow,
    stuImgShow,
    skipFinInStuApp,
    Subjects,
    AllottedSubjects,
    DescOptions,
    invCatgOpt,
    buttonStageOpt,
    paperTypeOpt,
    tyreConditionOpt,
    tyreSizeOpt,
    tyreStatusOpt,
    actionOpt,
    typeOpt,
    feeCategories,
    companyTypeOpt,
    byReferenceOpt,
    collectModeOpt,
    attendanceAlertOpt,
    autoAbsents,
    MifareUHFOptions,
    StudentsProgress,
    StudentConduct,
    ReasonForLeaving,
    StudentPromoted,
    compLessonPlan,
    compIdCards,
    idTagType,
    idTagWidth,
    compPayRoll,
    compSelfAss,
    compSpeakWell,
    compInvt,
    compEnquiry,
    compNaipunya,
    DynamicStudent,
    DynamicStudentDtls,
    FieldTypeOptions,
    HorzVertOptions,
    ShadowDirectionOptions,
    FontTypeOptions,
    FontAlignOptions,
    FontWeightOptions,
  };
};
