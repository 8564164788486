// ** Initial State
const initialState = {
  allData: [],
  filData: [],
  data: [],
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,

  attAllData: [],
  attData: [],
  attTotal: 1,
  attParams: {},

  saveData: [],
  sspinner: false,

  psData:[],
  psSpinner: false
};
const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const initialAttItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      // resp.serial = index + 1;
      if (resp.length > 0) {
        resp.forEach((att, i) => {
          if(att.inTime !== null){
            const H = +att.inTime.substr(0, 2);
            const h = H % 12 || 12;
            const ampm = H < 12 || H === 24 ? "AM" : "PM";
            att.inTime = h + att.inTime.substr(2, 3) + ' ' + ampm;
          }
          if(att.outTime !== null){
            const H = +att.outTime.substr(0, 2);
            const h = H % 12 || 12;
            const ampm = H < 12 || H === 24 ? "AM" : "PM";
            att.outTime = h + att.outTime.substr(2, 3) + ' ' + ampm;
          }
        })
        // console.log(resp.inTime, "InTimer")
        // const H = +resp.inTime.substr(0, 2);
        // const h = H % 12 || 12;
        // const ampm = H < 12 || H === 24 ? "AM" : "PM";
        // resp.startTime = h + resp.inTime.substr(2, 3) + ampm;
      }
    });
    return resData;
  } else {
    return [];
  }
};

const initialEmpAttItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const SalStReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SALARY_STATEMENT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        filData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_SALARY_STATEMENT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "GET_SAL_ST_FIL_DATA":
      return {
        ...state,
        data: action.data,
        filData: action.filData,
        total: action.totalPages,
        params: action.params,
      };
      case "GET_EMP_WISE_ATT_DTLS":
      return {
        ...state,
        attAllData: initialEmpAttItem(action.allData),
        attData: action.data,
        attTotal: action.totalPages,
        attParams: action.params,
      };
      case "GET_EMP_WISE_FIL_ATT_DTLS":
      return {
        ...state,
        attData: action.data,
        attTotal: action.totalPages,
        attParams: action.params,
      };
      case "SAVE_SALARY_STATEMENT_DATA":
      return {
        ...state,
        saveData: action.allData,
        sspinner: action.spinner
      };
    case "SAVE_SALARY_STATEMENT_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

      case "GET_MONTH_WISE_PAY_SLIP_DATA":
      return {
        ...state,
        psData: action.data,
        salData: initialItem(action.salData),
        attDtls: initialAttItem(action.attDtls),
        psSpinner: action.spinner
      };
    case "MONTH_WISE_PAY_SLIP_DATA_SPINNER":
      return {
        ...state,
        psSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default SalStReducer;
