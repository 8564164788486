import { DefaultConfig } from "../../../../../../settings/config";

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  termList: [],
  feeCallData: [],
  feeSaveData: [],
  concData: [],
  total: 1,
  totalPenSumData: [],
  params: {},
  spinner: false,
  fspinner: false,
  cspinner: false,
  tokenExp: false,
  columns: [],
};

const initialItem = (resData) => {
  if (resData) {
    const classes = DefaultConfig().Classes;
    const sections = DefaultConfig().Sections;
    const departments = DefaultConfig().Departments;
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      resp["dateofjoin"] = DefaultConfig().DateFormatMonth(resp.DATEOFJOIN);
      resp["balance"] =
        resp.BALANCEPERCENT === "-Infinity"
          ? 0
          : resData.BALANCEPERCENT === "NaN"
          ? 0
          : DefaultConfig().AmtFormat(resp.BALANCEPERCENT);
      resp["cls"] = classes.find((item) => item.value === resp.CLASS)
        ? classes.find((item) => item.value === resp.CLASS).label
        : "";
      resp["dept"] = departments.find((item) => item.value === resp.DEPARTMENT)
        ? departments.find((item) => item.value === resp.DEPARTMENT).label
        : "";
      resp["sec"] = sections.find((item) => item.value === resp.SECTION)
        ? sections.find((item) => item.value === resp.SECTION).label
        : "";
      resp["serial"] = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};
const getColumns = (resData) => {
  if (resData) {
    const columnList = [];
    resData.forEach((resp) => {
      columnList.push({
        name: resp,
        minWidth: "0px",
        maxWidth: "100px",
        selector: resp,
        sortable: true,
        showhide: true,
        cell: (row) => <div className="text-right w-100">{row.resp}</div>,
      });
    });
    return columnList;
  } else {
    return [];
  }
};

const getTerms = (terms) => {
  if (terms) {
    terms.forEach((respp, index) => {
      respp.forEach((resp, index) => {
        if (resp.columnName === "1") {
          resp[`termname`] = `term${resp.columnName}`;
          // console.log("sss");
          resp[`term${resp.columnName} Paid`] = resp.paid;
          resp[`term${resp.columnName} Total`] = resp.total;
          resp[`term${resp.columnName} Balance`] = resp.balance;
        } else if (resp.columnName === "2") {
          resp[`termname`] = `term${resp.columnName}`;
          resp[`term${resp.columnName} Paid`] = resp.paid;
          resp[`term${resp.columnName} Total`] = resp.total;
          resp[`term${resp.columnName} Balance`] = resp.balance;
        } else if (resp.columnName === "3") {
          resp[`termname`] = `term${resp.columnName}`;
          resp[`term${resp.columnName} Paid`] = resp.paid;
          resp[`term${resp.columnName} Total`] = resp.total;
          resp[`term${resp.columnName} Balance`] = resp.balance;
        } else if (resp.columnName === "4") {
          resp[`termname`] = `term${resp.columnName}`;
          resp[`term${resp.columnName} Paid`] = resp.paid;
          resp[`term${resp.columnName} Total`] = resp.total;
          resp[`term${resp.columnName} Balance`] = resp.balance;
        } else if (resp.columnName === "5") {
          resp[`termname`] = `term${resp.columnName}`;
          resp[`term${resp.columnName} Paid`] = resp.paid;
          resp[`term${resp.columnName} Total`] = resp.total;
          resp[`term${resp.columnName} Balance`] = resp.balance;
        } else if (resp.columnName === "6") {
          resp[`termname`] = `term${resp.columnName}`;
          resp[`term${resp.columnName} Paid`] = resp.paid;
          resp[`term${resp.columnName} Total`] = resp.total;
          resp[`term${resp.columnName} Balance`] = resp.balance;
        } else if (resp.columnName === "7") {
          resp[`termname`] = `term${resp.columnName}`;
          resp[`term${resp.columnName} Paid`] = resp.paid;
          resp[`term${resp.columnName} Total`] = resp.total;
          resp[`term${resp.columnName} Balance`] = resp.balance;
        } else if (resp.columnName === "8") {
          resp[`termname`] = `term${resp.columnName}`;
          resp[`term${resp.columnName} Paid`] = resp.paid;
          resp[`term${resp.columnName} Total`] = resp.total;
          resp[`term${resp.columnName} Balance`] = resp.balance;
        } else if (resp.columnName === "9") {
          resp[`termname`] = `term${resp.columnName}`;
          resp[`term${resp.columnName} Paid`] = resp.paid;
          resp[`term${resp.columnName} Total`] = resp.total;
          resp[`term${resp.columnName} Balance`] = resp.balance;
        } else if (resp.columnName === "10") {
          resp[`termname`] = `term${resp.columnName}`;
          resp[`term${resp.columnName} Paid`] = resp.paid;
          resp[`term${resp.columnName} Total`] = resp.total;
          resp[`term${resp.columnName} Balance`] = resp.balance;
        } else if (resp.columnName === "11") {
          resp[`termname`] = `term${resp.columnName}`;
          resp[`term${resp.columnName} Paid`] = resp.paid;
          resp[`term${resp.columnName} Total`] = resp.total;
          resp[`term${resp.columnName} Balance`] = resp.balance;
        } else if (resp.columnName === "12") {
          resp[`termname`] = `term${resp.columnName}`;
          resp[`term${resp.columnName} Paid`] = resp.paid;
          resp[`term${resp.columnName} Total`] = resp.total;
          resp[`term${resp.columnName} Balance`] = resp.balance;
        }
      });

      respp.index = index + 1;
    });
    return terms;
  } else {
    return [];
  }
};

const PendingPayments = (state = initialState, action) => {
  // console.log(action, "Reducer Console")
  switch (action.type) {
    case "GET_ALL_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        totalPenSumData: initialItem(action.allData),
        data: action.data,
        columns: action.columns,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_PENDING_DATA":
      return {
        ...state,
        // allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        totalPenSumData: action.totalPenSumData,
        params: action.params,
      };
    case "SMS_DATA":
      return {
        ...state,
        saveData: action.allData,
        spinner: action.spinner,
      };
    case "PENDING_PAYMENT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_FEE_DATA":
      return {
        ...state,
        feeCallData: action.feeCallData,
        fspinner: action.spinner,
      };
    case "STU_FEECALL_LOADING_SPINNER":
      return {
        ...state,
        fspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "SAVE_FEE_DATA":
      return {
        ...state,
        feeSaveData: action.feeSaveData,
        fspinner: action.spinner,
      };
    case "STU_FEECALLSAVE_LOADING_SPINNER":
      return {
        ...state,
        fspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "STU_CONCESSION_LOADING_SPINNER":
      return {
        ...state,
        cspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_CONC_DATA":
      return {
        ...state,
        concData: action.concData,
        cspinner: action.spinner,
      };
    default:
      return { ...state };
  }
};
export default PendingPayments;
