import { DefaultConfig } from "../../../../../settings/config";

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,

  diaryData: [],
  dspinner: false,

  eventData: [],
  evspinner: false,

  safData: [],
  safspinner: false,

  sattData: [],

  cwlpData: [],
  cwlpspinner: false,

  prData: "",
  prspinner: false,
  examData: [],
  exspinner: false,

  onExamData: [],
  onExspinner: false,

  stQueData: [],
  stSpinner: false,

  allSRData: [],
  columnData: [],
  srdata: [],
  srtotal: 1,
  srparams: {},
  srSpinner: false,

  allSWAData: [],
  swaData: [],
  swaTotal: 1,
  swaParams: {},
  swaSpinner: false,

  syrData: '',
  syrSpinner: false,

  answrdCount: "",
  qstnsCount: "",
  score: "",
  sapData: [],
  sapSpinner: false,

  ssaData: {},
  ssaSpinner: false,
};

const getColumns = (data) => {
  const Subjects = DefaultConfig().Subjects
  var columns = []
  columns.push(
    {
      name: "SNO",
      minWidth: "0px",
      selector: "serial",
      sortable: true,
      showhide: true,
      cell: (row) => row.serial,
    },
    {
      name: "Exam",
      minWidth: "0px",
      selector: "acr",
      sortable: true,
      showhide: true,
      cell: (row) => row.acr,
    },
  )
    const subjects = data
    subjects.forEach(element => {
      const subName = Subjects.find((find) => find.value === element) && Subjects.find((find) => find.value === element).label
      columns.push({
        name: subName,
        minWidth: "0px",
        selector: subName,
        sortable: true,
        showhide: true,
        cell: (row) => row.subName,
      })
    });
  columns.push(
    {
      name: "Total",
      minWidth: "0px",
      selector: "secMarks",
      sortable: true,
      showhide: true,
      cell: (row) => row.secMarks,
    },
    {
      name: "Per. (%)",
      minWidth: "0px",
      selector: "percent",
      sortable: true,
      showhide: true,
      cell: (row) => row.percent,
    },
    {
      name: "Grade",
      minWidth: "0px",
      selector: "grade",
      sortable: true,
      showhide: true,
      cell: (row) => row.grade,
    }
  )

  return columns
}


const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      resp.subjAnalytics.forEach((sub) => {
        sub.avg = sub.avg ? Number(Math.round(sub.avg)) : 0;
        sub.min = sub.min ? Number(Math.round(sub.min)) : 0;
        sub.max = sub.max ? Number(Math.round(sub.max)) : 0;
        sub.failCount = sub.failCount ? sub.failCount : 0;
      });
    });
    return resData;
  } else {
    return [];
  }
};

const initialStuAttItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const getAllData = (data) => {

  data.forEach((item, index) => {
   
    const examMarks = item.subWiseList
   
    item.branchName = Branches.find((find) => find.value === item.branchId) ? Branches.find((find) => find.value === item.branchId).branchName : ''
    // const assMarks = item.assMarks
    // let totalMarks = 0
    // let summarks = 0
    // let percent = 0
    // let grade = ''
    // subjects.forEach((sub) => {
    //   totalMarks += sub.max
    //   if (incmarks) {
    //     const subA = assMarks[sub.subRef]
    //     const checkassVal = !isNaN(subA)
    //     if (checkassVal) {
    //       summarks += Number(subA)
    //     }
    //   }
    //   const subE = examMarks[sub.subRef]
    //   const checkexmVal = !isNaN(subE)
    //   if (checkexmVal) {
    //     summarks += Number(subE)
    //   }
    //   // console.log(subA, subE, checkassVal, checkexmVal)
    // })
    // percent = (summarks / totalMarks) * 100
    // percent = Number.parseFloat(percent).toFixed(2)

    // for (let i = 0; i < Grades.length; i++) {
    //   if (Number(percent) >= Grades[i].minPercent && Grades[i].maxPercent >= Number(percent)) {
    //     grade = Grades[i].grade
    //     break
    //   }
    // }
    // item.branchName = Branches.find((find) => find.value === item.branchId) ? Branches.find((find) => find.value === item.branchId).branchName : ''
    // item.summarks = Number(summarks)
    // item.total = (summarks > 0 ? (summarks + ' / ' + totalMarks) : '')
    // item.percent = summarks > 0 ? Number(percent) : ''
    // item.grade = summarks > 0 ? grade : ''
  })
  // const sortData = sorting('summarks', 'desc', resultEntries)
  // data['resultEntries'] = sortData
  return data
}

const StudentDashboard = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STU_REA_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "GET_STU_REA_DATA_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_DIARY_DATA":
      return {
        ...state,
        diaryData: action.allData,
        spinner: action.spinner,
      };
    case "GET_DIARY_DATA_LOADING_SPINNER":
      return {
        ...state,
        dspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STU_ATT_FEE_DATA":
      return {
        ...state,
        safData: action.allData,
        safspinner: action.spinner,
      };
    case "GET_STU_ATT_FEE_DATA_LOADING_SPINNER":
      return {
        ...state,
        safspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_STU_ATT_COUNT_DATA":
      return {
        ...state,
        sattData: initialStuAttItem(action.allData),
      };

    case "GET_CLS_WISE_LESSON_PLAN":
      return {
        ...state,
        cwlpData: action.allData,
        cwlpspinner: action.spinner,
      };
    case "GET_CLS_WISE_LESSON_PLAN_LOADING_SPINNER":
      return {
        ...state,
        cwlpspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "PAYMENT_RESPONSE":
      return {
        ...state,
        prData: action.data,
        prspinner: action.spinner,
      };
    case "GET_PAYMENT_REQUEST_LOADING_SPINNER":
      return {
        ...state,
        prspinner: action.spinner,
      };
    case "GET_EXAM_DATA":
      return {
        ...state,
        examData: action.allData,
        exspinner: action.spinner,
      };
    case "GET_EXAM_TIME_TABLE_DATA_LOADING_SPINNER":
      return {
        ...state,
        exspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_EVENTS_DATA":
      return {
        ...state,
        eventData: action.allData,
        evspinner: action.spinner,
      };
    case "GET_EVENTS_LOADING_SPINNER":
      return {
        ...state,
        evspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

      

    case "GET_STU_ONLINE_EXAM_DATA":
      return {
        ...state,
        onExamData: action.allData,
        onExspinner: action.spinner,
      };
    case "ONLINE_EXAMS_STU_LOADING_SPINNER":
      return {
        ...state,
        onExspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

      case "GET_QUESTIONS_ST_DATA":
      return {
        ...state,
        stQueData: action.allData,
        stSpinner: action.spinner,
      };
    case "QUESTIONS_ST_LOADING_SPINNER":
      return {
        ...state,
        stSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_STU_RESULT_DATA":
      return {
        ...state,
        columnData: getColumns(action.subRefs),
        allSRData: initialStuAttItem(action.examData),
        srdata: action.data,
        srtotal: action.totalPages,
        srparams: action.params,
        srSpinner: action.spinner,
      };
    case "GET_STU_RESULT_DATA_LOADING_SPINNER":
      return {
        ...state,
        srSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STU_RESULT_DATA_FILTER":
      return {
        ...state,
        srdata: action.data,
        srtotal: action.totalPages,
        srparams: action.params,
      };

      case "GET_STU_MONTH_WISE_ATT_DATA":
        return {
          ...state,
          allSWAData: initialStuAttItem(action.allData),
          swaData: action.data,
          swaTotal: action.totalPages,
          swaParams: action.params,
          swaSpinner: action.spinner,
        };
      case "GET_STU_MONTH_WISE_ATT_LOADING_SPINNER":
        return {
          ...state,
          swaSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_STU_MONTH_WISE_DATA_FILTER":
          return {
            ...state,
            swadata: action.data,
            swaTotal: action.totalPages,
            swaParams: action.params,
          };
      case "GET_SYLLABUS_REF_LOADING_SPINNER":
        return {
          ...state,
          syrSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_SYLLABUS_REF":
          return {
            ...state,
            syrData: action.data,
            syrSpinner: action.spinner,
          };
      case "GET_STU_SELF_ASSESMENT_LOADING_SPINNER":
        return {
          ...state,
          sapSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_STU_SELF_ASSESMENT_DATA":
          return {
            ...state,
            answrdCount: action.answrdCount,
            qstnsCount: action.qstnsCount,
            score: action.score,
            sapData: action.allData,
            sapSpinner: action.spinner,
          };
      case "GET_SAVE_SELF_ASSESMENT_LOADING_SPINNER":
        return {
          ...state,
          ssaSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_SAVE_SELF_ASSESMENT_DATA":
          return {
            ...state,
            ssaData: action.allData,
            ssaSpinner: action.spinner,
          };
    default:
      return { ...state };
  }
};
export default StudentDashboard;
