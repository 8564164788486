import axios from "axios";
import { toast } from "react-toastify";
import { paginateArray } from "../../../../../../@fake-db/utils";
import { DefaultConfig } from "../../../../../../settings/config";
import ToastMessage from "../../../../../../@core/components/toast-messages";

const Classes = DefaultConfig().Classes;
const Sections = DefaultConfig().Sections;

// ** Get all Data
export const getAllData = (params, compId) => {
  return async (dispatch) => {
    // const compId = DefaultConfig().companyId;
    const authAxios = DefaultConfig().authAxios;
    dispatch({
      type: "SIBLING_MAP_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .get(`students/studentBasicInfo/${compId}`)
      .then((response) => {
        const resData = response.data.value;
        const { perPage = 10, page = 1 } = params;
        if (resData) {
          // console.log(resData, "get sibling");
          const siblingInfo = resData.siblingInfoList;
          const stuInfoList =
            resData.stuInfoList &&
            resData.stuInfoList.map((ven) => ({
              value: ven.sno,
              label: ven.name,
              cls: ven.cls,
              sec: ven.sec,
              village: ven.village,
            }));
          if (siblingInfo) {
            siblingInfo.forEach((item, index) => {
              item["child1name"] = stuInfoList.find(
                (cc) => cc.value === item.child1
              )
                ? stuInfoList.find((cc) => cc.value === item.child1).label
                : "";
              const cls1 = stuInfoList.find((cc) => cc.value === item.child1)
                ? stuInfoList.find((cc) => cc.value === item.child1).cls
                : "";
              item["cls1name"] = Classes.find((cc) => cc.value === cls1)
                ? Classes.find((cc) => cc.value === cls1).label
                : "";
              const sec1 = stuInfoList.find((cc) => cc.value === item.child1)
                ? stuInfoList.find((cc) => cc.value === item.child1).sec
                : "";
              item["sec1name"] = Sections.find((cc) => cc.value === sec1)
                ? Sections.find((cc) => cc.value === sec1).label
                : "";
              item["village1"] = stuInfoList.find(
                (cc) => cc.value === item.child1
              )
                ? stuInfoList.find((cc) => cc.value === item.child1).village
                : "";
              item.serial = index + 1;
            });
          }
          if (siblingInfo) {
            siblingInfo.forEach((item, index) => {
              item["child2name"] = stuInfoList.find(
                (cc) => cc.value === item.child2
              )
                ? stuInfoList.find((cc) => cc.value === item.child2).label
                : "";
              const cls2 = stuInfoList.find((cc) => cc.value === item.child2)
                ? stuInfoList.find((cc) => cc.value === item.child2).cls
                : "";
              item["cls2name"] = Classes.find((cc) => cc.value === cls2)
                ? Classes.find((cc) => cc.value === cls2).label
                : "";
              const sec2 = stuInfoList.find((cc) => cc.value === item.child2)
                ? stuInfoList.find((cc) => cc.value === item.child2).sec
                : "";
              item["sec2name"] = Sections.find((cc) => cc.value === sec2)
                ? Sections.find((cc) => cc.value === sec2).label
                : "";
              item["village2"] = stuInfoList.find(
                (cc) => cc.value === item.child2
              )
                ? stuInfoList.find((cc) => cc.value === item.child2).village
                : "";

              item.serial = index + 1;
            });
          }
          if (siblingInfo) {
            siblingInfo.forEach((item, index) => {
              item["child3name"] = stuInfoList.find(
                (cc) => cc.value === item.child3
              )
                ? stuInfoList.find((cc) => cc.value === item.child3).label
                : "";
              const cls3 = stuInfoList.find((cc) => cc.value === item.child3)
                ? stuInfoList.find((cc) => cc.value === item.child3).cls
                : "";
              item["cls3name"] = Classes.find((cc) => cc.value === cls3)
                ? Classes.find((cc) => cc.value === cls3).label
                : "";
              const sec3 = stuInfoList.find((cc) => cc.value === item.child3)
                ? stuInfoList.find((cc) => cc.value === item.child3).sec
                : "";
              item["sec3name"] = Sections.find((cc) => cc.value === sec3)
                ? Sections.find((cc) => cc.value === sec3).label
                : "";
              item["village3"] = stuInfoList.find(
                (cc) => cc.value === item.child3
              )
                ? stuInfoList.find((cc) => cc.value === item.child3).village
                : "";

              item.serial = index + 1;
            });
          }
          if (siblingInfo) {
            siblingInfo.forEach((item, index) => {
              item["child4name"] = stuInfoList.find(
                (cc) => cc.value === item.child4
              )
                ? stuInfoList.find((cc) => cc.value === item.child4).label
                : "";
              const cls4 = stuInfoList.find((cc) => cc.value === item.child4)
                ? stuInfoList.find((cc) => cc.value === item.child4).cls
                : "";
              item["cls4name"] = Classes.find((cc) => cc.value === cls4)
                ? Classes.find((cc) => cc.value === cls4).label
                : "";
              const sec4 = stuInfoList.find((cc) => cc.value === item.child4)
                ? stuInfoList.find((cc) => cc.value === item.child4).sec
                : "";
              item["sec4name"] = Sections.find((cc) => cc.value === sec4)
                ? Sections.find((cc) => cc.value === sec4).label
                : "";
              item["village4"] = stuInfoList.find(
                (cc) => cc.value === item.child4
              )
                ? stuInfoList.find((cc) => cc.value === item.child4).village
                : "";

              item.serial = index + 1;
            });
          }
          const Branches = DefaultConfig().SubBranchList;

          if (Branches) {
            siblingInfo.forEach((item, index) => {
              item["branch"] = Branches.find((cc) => cc.compId === item.compId)
                ? Branches.find((cc) => cc.compId === item.compId).branchName
                : "";
              item.serial = index + 1;
            });
          }
          dispatch({
            type: "GET_ALL_SIBLING_MAP_DATA",
            allData: siblingInfo,
            stuInfoData: stuInfoList,
            data: paginateArray(siblingInfo, perPage, page),
            totalPages: siblingInfo.length,
            params,
            spinner: true,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "SIBLING_MAP_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "SIBLING_MAP_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"students/studentBasicInfo" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

// ** Get data on page or row change
export const getData = (params) => {
  return (dispatch, getState) => {
    const {
      q = "",
      perPage = 10,
      page = 1,
      sortname = "",
      sortorder = "",
    } = params;
    console.log("editt");
    const resData = getState().siblingMapping.allData;
    if (resData) {
      let filteredData = [];
      const queryLowered = q.toLowerCase();
      filteredData = resData.filter(
        (students) =>
          (students.branch &&
            students.branch.toLowerCase().includes(queryLowered)) ||
          (students.child1name &&
            students.child1name.toLowerCase().includes(queryLowered)) ||
          (students.child2name &&
            students.child2name.toLowerCase().includes(queryLowered)) ||
          (students.child3name &&
            students.child3name.toLowerCase().includes(queryLowered)) ||
          (students.child4name &&
            students.child4name.toLowerCase().includes(queryLowered))
      );
      const sorting = DefaultConfig().sorter;
      const sortData = sortorder
        ? sorting(sortname, sortorder, filteredData)
        : filteredData;
      dispatch({
        type: "GET_SIBLING_MAP_DATA",
        data: paginateArray(sortData, perPage, page),
        totalPages: sortData.length,
        params,
      });
    }
  };
};

// ** Add new class
export const addSibling = (siblingObj) => {
  return async (dispatch, getState) => {
    // const companyId = DefaultConfig().companyId;
    //const employeeId = DefaultConfig().employeeId;
    const compId = DefaultConfig().companyId;
    const Branches = DefaultConfig().Branches;
    const authAxios = DefaultConfig().authAxios;
    dispatch({
      type: "SIBLING_MAP_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    const objectData = {};
    objectData["compId"] = compId;
    objectData["child1"] = siblingObj.child1;
    objectData["child2"] = siblingObj.child2;
    objectData["child3"] = siblingObj.child3;
    objectData["child4"] = siblingObj.child4;
    await authAxios
      .post("students/siblingSave", objectData)
      .then((response) => {
        const resData = response.data.value;
        if (resData) {
          console.log(resData, "post classes");
          const allData = getState().siblingMapping.allData;
          const stuData = getState().siblingMapping.stuData;
          // console.log(stuData, "stuu");
          //console.log(Branches);
          resData["branch"] = Branches.find(
            (item) => item.value === resData.compId
          )
            ? Branches.find((item) => item.value === resData.compId).branchName
            : "";
          resData["child1name"] = stuData.find(
            (cc) => cc.value === resData.child1
          )
            ? stuData.find((cc) => cc.value === resData.child1).label
            : "";
          resData["child2name"] = stuData.find(
            (cc) => cc.value === resData.child2
          )
            ? stuData.find((cc) => cc.value === resData.child2).label
            : "";
          resData["child3name"] = stuData.find(
            (cc) => cc.value === resData.child3
          )
            ? stuData.find((cc) => cc.value === resData.child3).label
            : "";
          resData["child4name"] = stuData.find(
            (cc) => cc.value === resData.child4
          )
            ? stuData.find((cc) => cc.value === resData.child4).label
            : "";

          allData.unshift(resData);

          dispatch({
            type: "ADD_SIBLING_MAP",
            allData,
            spinner: true,
          });
          toast.success(
            <ToastMessage message={"Added Successfully"} status={"success"} />,
            { hideProgressBar: true }
          );
        }
      })
      .then(() => {
        dispatch(getData(getState().siblingMapping.params));
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "SIBLING_MAP_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "SIBLING_MAP_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"students/siblingSave" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

// ** Get sms
export const getSibling = (id, title) => {
  return (dispatch, getState) => {
    console.log(id, title);
    const allData = getState().siblingMapping.allData;
    //console.log(allData);
    const findOne = allData.find((find) => find.serial === id);
    // console.log(findOne, "classesid");
    dispatch({
      type: "GET_SIBLING_MAP",
      selectedSibling: findOne,
      actionTitle: title,
    });
  };
};

// ** Update class
export const updateSibling = (siblingObj) => {
  return async (dispatch, getState) => {
    console.log(siblingObj);
    const companyId = DefaultConfig().companyId;
    const Branches = DefaultConfig().Branches;
    const authAxios = DefaultConfig().authAxios;
    dispatch({
      type: "SIBLING_MAP_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .post("students/siblingSave", siblingObj)
      .then((response) => {
        const resData = response.data.value;
        if (resData) {
          const stuData = getState().siblingMapping.stuData;

          resData["branch"] = Branches.find(
            (item) => item.value === resData.compId
          )
            ? Branches.find((item) => item.value === resData.compId).branchName
            : "";
          resData["child1name"] = stuData.find(
            (cc) => cc.value === resData.child1
          )
            ? stuData.find((cc) => cc.value === resData.child1).label
            : "";
          resData["child2name"] = stuData.find(
            (cc) => cc.value === resData.child2
          )
            ? stuData.find((cc) => cc.value === resData.child2).label
            : "";
          resData["child3name"] = stuData.find(
            (cc) => cc.value === resData.child3
          )
            ? stuData.find((cc) => cc.value === resData.child3).label
            : "";
          resData["child4name"] = stuData.find(
            (cc) => cc.value === resData.child4
          )
            ? stuData.find((cc) => cc.value === resData.child4).label
            : "";

          const allData = getState().siblingMapping.allData;
          const findIndex = allData.findIndex(
            (find) => find.ref === siblingObj.ref
          );

          if (findIndex === -1) {
            allData.push(resData);
          } else {
            allData[findIndex] = resData;
          }
          allData[findIndex] = resData;
          dispatch({
            type: "UPDATE_SIBLING_MAP",
            allData,
            spinner: true,
          });
          toast.success(
            <ToastMessage
              message={"Updated Successfully"}
              status={"success"}
            />,
            { hideProgressBar: true }
          );
        }
      })
      .then(() => {
        dispatch(getData(getState().siblingMapping.params));
      })

      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "SIBLING_MAP_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "SIBLING_MAP_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"students/siblingSave" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

