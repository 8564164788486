// ** Initial State
const initialState = {
  staffIOData: [],
  staffMIOData: [],
  allData: [],
  columnData: [],
  data: [],
  total: 1,
  params: {},
  spinner: false,
  mspinner: false,
  tokenExp: false,

  lallData: [],
  ldata: [],
  ltotal: 1,
  lparams: {},
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const getColumns = (data) => {
  var columns = [];
  columns.push(
    {
      name: "S.No",
      minWidth: "0px",
      selector: "serial",
      sortable: true,
      showhide: true,
      cell: (row) => row.serial,
    },
    {
      name: "Emp Id",
      minWidth: "0px",
      selector: "empId",
      sortable: true,
      showhide: true,
      cell: (row) => row.empId,
    },
    {
      name: "Employee Name",
      minWidth: "0px",
      selector: "name",
      sortable: true,
      showhide: true,
      cell: (row) => row.name,
    },
    {
      name: "Designation",
      minWidth: "0px",
      selector: "empdesg",
      sortable: true,
      showhide: true,
      cell: (row) => row.designation,
    },
  );
  data[0].inOutList.forEach((element, index) => {
    columns.push({
      name: element.date,
      minWidth: "0px",
      selector: `d${index+1}`,
      sortable: true,
      showhide: true,
      cell: (row) => row[`d${index+1}`],
    });
  });
  return columns;
};

const StaffIOAttendance = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_STF_DATA":
      return {
        ...state,
        staffIOData: action.data,
        spinner: action.spinner
      };
    case "STF_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STFIO_ATT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      };
    case "GET_STAFF_FIL_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages
      };
    case "GET_STFLIO_ATT_DATA":
      return {
        ...state,
        lallData: initialItem(action.allData),
        ldata: action.data,
        ltotal: action.totalPages,
        lparams: action.params,
        spinner: action.spinner
      };
    case "GET_STAFFL_FIL_DATA":
      return {
        ...state,
        ldata: action.data,
        ltotal: action.totalPages
      };
    case "GET_ALL_STF_MON_DATA":
      return {
        ...state,
        staffMIOData: action.data,
        mspinner: action.spinner
      };
    case "STF_MONTH_LOADING_SPINNER":
      return {
        ...state,
        mspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STFIO_MON_ATT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        columnData: getColumns(action.allData),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      };
    case "GET_STAFF_FIL_MON_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages
      };
    default:
      return { ...state };
  }
};
export default StaffIOAttendance;
