// ** Initial State
const initialState = {
  accountTypes: [],
  subaccountTypes: [],
  innersubaccountTypes: [],
  feeHeadsData: [],
  addFeeData: [],
  fuDate: '',
  saveConcData: [],

  stuData: [],
  stuFeeData: {},
  stuFeeSumData: {},
  sfspinner: false,
  feeDetails: [],
  payuDetails: [],
  bankData: [],
  allData: [],
  paymentData: [],
  feecatgData: [],
  data: [],
  feeConcData: [],
  fsspinner: false,

  total: 1,
  params: {},
  spinner: false,
  showDiv: false,
  sspinner: false,
  ispinner: false,
  espinner: false,
  fspinner: false,
  pspinner: false,
  fespinner: false,
  aispinner: false,
  btnSpinner: true,
  btnType: "",
  tokenExp: false,

  payAdjust: [],
  paspinner: false,

  updConcData: {},
  updConcSpinner: false,

  updFeeClrYearData: {},
  updFeeClrYearSpinner: false,
  
};
const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const incomecollection = (state = initialState, action) => {
  switch (action.type) {
    case "GET_IN_COL_ACCOUNT_DATA":
      return {
        ...state,
        accountTypes: action.data,
        spinner: action.spinner,
      };
    case "GET_IN_COL_SUB_ACCOUNT_DATA":
      return {
        ...state,
        subaccountTypes: action.data,
        sspinner: action.spinner,
      };
    case "GET_IN_COL_INN_SUB_ACCOUNT_DATA":
      return {
        ...state,
        innersubaccountTypes: action.data,
        ispinner: action.spinner,
      };
    case "GET_IN_COL_ALL_DATA":
      return {
        ...state,
        feeData: action.data,
        espinner: action.spinner,
      };
    case "GET_IN_COL_BANK_DATA":
      return {
        ...state,
        bankData: action.data,
        spinner: action.spinner,
      };
    case "GET_IN_COL_FEE_HEAD_DATA":
      return {
        ...state,
        feeHeadsData: action.data,
        spinner: action.spinner,
      };
    case "ADD_IN_COL":
      return {
        ...state,
        addFeeData: action.data,
        fuDate: action.fuDate,
        // stuFeeData: [],
        aispinner: action.spinner,
        btnSpinner: action.btnSpinner,
        btnType: action.btnType,
      };

    case "SAVE_CONCESSION":
      return {
        ...state,
        saveConcData: action.data,
        fspinner: action.spinner,
        showDiv: action.showDiv,
        feeDetails: action.feeDetails,
      };
    case "SAVE_CONCESSION_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "SAVE_FEESES":
      return {
        ...state,
        //feeConcData: action.data,
        fsspinner: action.spinner,
        // showDiv: action.showDiv,
        // feeDetails: action.feeDetails,
      };
    case "FEES_CONCESSION_LOADING_SPINNER":
      return {
        ...state,
        fsspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "IN_COL_SUB_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "IN_COL_INN_SUB_LOADING_SPINNER":
      return {
        ...state,
        ispinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "IN_COL_ALL_DATA_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "IN_COL_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "IN_COL_SAVE_LOADING_SPINNER":
      return {
        ...state,
        fspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_IN_COL_PAYMENT_DATA":
      // console.log(action, "Reducer Payment Data");
      return {
        ...state,
        // paymentData: action.data,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        pspinner: action.spinner,
      };
    case "GET_PAY_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };

    case "IN_COL_PAYMENT_LOADING_SPINNER":
      return {
        ...state,
        pspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STU_DATA":
      return {
        ...state,
        stuData: action.data,
        pspinner: action.spinner,
      };
    case "STU_LOADING_SPINNER":
      return {
        ...state,
        pspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STU_FEE_DATA":
      return {
        ...state,
        stuFeeData: action.data,
        stuFeeSumData: action.data,
        feeDetails: action.data.feeDetails,
        payuDetails: action.data.payuDetails,
        sfspinner: action.spinner,
      };
    case "GET_STU_FEE_LOADING_SPINNER":
      return {
        ...state,
        sfspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ALL_CAT_DATA":
      return {
        ...state,
        feecatgData: initialItem(action.feeCatgData),

        fespinner: action.spinner,
      };
    case "LOADING_CAT_SPINNER":
      return {
        ...state,
        fespinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "SAVE_PAY_ADJUST":
      return {
        ...state,
        payAdjust: action.data,
        paspinner: action.spinner,
      };
    case "PAY_ADJUSTMENT_LOADING_SPINNER":
      return {
        ...state,
        paspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_UPDATED_CONCESSION_DATA":
      return {
        ...state,
        updConcData: action.updConcData,
        updConcSpinner: action.spinner,
      };
    case "GET_UPDATED_CONCESSION_DATA_LOADING_SPINNER":
      return {
        ...state,
        updConcSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "UPDATE_FEE_CLEAR_YEAR":
      return {
        ...state,
        updFeeClrYearData: action.resData,
        updFeeClrYearSpinner: action.spinner,
      };
    case "UPDATE_FEE_CLEAR_YEAR_LOADING_SPINNER":
      return {
        ...state,
        updFeeClrYearSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default incomecollection;
