import { DefaultConfig } from "../../../../../../settings/config";
const AcademicYear = DefaultConfig().AcademicYear;
const DateFormatMonth = DefaultConfig().DateFormatMonth;
const AmountFormat = DefaultConfig().AmountFormat;

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,
};

const initialItem = (ledgerData) => {
  if (ledgerData) {
    ledgerData.forEach((resp, index) => {
      resp["date"] = DefaultConfig().DateFormatMonth(resp.date);
      resp["dueDates"] = DefaultConfig().DateFormatMonth(resp.dueDate);
      resp.serial = index + 1;
    });
    return ledgerData;
  } else {
    return [];
  }
};

const EmployeeVendReport = (state = initialState, action) => {
  switch (action.type) {
    case "GETALL_LATEST_REP_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: initialItem(action.data),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };

    case "EMPVEND_LATEST_REPORT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_EMPVEND_LATEST_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "UPD_INV_EMPVENDOR":
      return {
        ...state,
        allData: action.allData,
        spinner: action.spinner,
      };

    default:
      return { ...state };
  }
};
export default EmployeeVendReport;
