import { DefaultConfig } from "../../../../../settings/config";
// const Branches = DefaultConfig().Branches;
// ** Initial State
const initialState = {
  balanceData: [],
  spinner: false,
  tokenExp: false,
  incomeData: [],
  billData: [],
  recData: [],

  curtotalIncData: [],
  ctispinner: true,

  tispinner: false,
  totalIncData: [],
  totalIncSumData: [],
  totalIncASumData: [],
  totalIncCSumData: [],

  allData: [],
  data: [],
  total: 1,
  params: {},

  erAllData: [],
  erData: [],
  erTotal: 1,
  params: {},
  erspinner: false,

  expYData: '',
  expYspinner: false,

  aallData: [],
  adata: [],
  atotal: 1,
  aparams: {},

  callData: [],
  cdata: [],
  ctotal: 1,
  cparams: {},
  cmodel: false,
  selectTitle: "",

  ballData: [],
  bdata: [],
  btotal: 1,
  bparams: {},
  bspinner: true,
  totalBalAmount: 0,
  totalCashAmount: 0,
  totalBankBalList: [],
  totalBalSumData: [],

  blallData: [],
  bldata: [],
  bltotal: 1,
  blparams: {},
  blspinner: true,
  totalBillAmount: 0,
  outstandingBillAmount: 0,
  totalBillList: [],
  totalBillSumData: [],

  trallData: [],
  trdata: [],
  trtotal: 1,
  trparams: {},
  brspinner: false,
  trspinner: false,
  totalRecsAmount: 0,
  totaldueAmount: 0,
  totalRecList: [],
  totalRecSumData: [],
  termList: [],
  selTermsList: [],
  recspinner: false,
  paidGData: [],
  concGData: [],
  balGData: [],
  clsListGData: [],

  trcwallData: [],
  trcwdata: [],
  trcwtotal: 1,
  trcwparams: {},
  trcwspinner: false,
  totalRecsCWAmount: 0,
  totalCWdueAmount: 0,
  totalRecCWList: [],
  totalRecCWSumData: [],
  termList: [],
  selTermsList: [],
  paidCWGData: [],
  concCWGData: [],
  balCWGData: [],
  clsListCWGData: [],

  invallData: [],
  invdata: [],
  idData: [],
  invtotal: 1,
  invparams: {},
  invspinner: true,
  totalInvAmount: 0,
  totalInvDueAmount: 0,
  totalInvList: [],
  catgWiseInvDtls: [],
  catgWiseList: [],
  totalInvSumData: [],

  cwallData: [],
  cwdata: [],
  cwtotal: 1,
  cwparams: {},
  cwspinner: true,
  totalCWSumData: [],

  staffAttData: {},
  stallData: [],
  stdata: [],
  sttotal: 1,
  stparams: {},
  stspinner: false,

  dtallData: [],
  dtdata: [],
  dttotal: 1,
  dtparams: {},
  dayWisePeriodData: [],

  budgetList: [],
  investBudget: [],
  totalBudget: [],
  busspinner: false,

  stuAttData: {},
  stuspinner: false,
  stuallData: [],
  studata: [],
  stutotal: 1,
  stuparams: {},

  stballData: [],
  stbdata: [],
  stbtotal: 1,
  stbparams: {},

  stdallData: [],
  stddata: [],
  stdtotal: 1,
  stdparams: {},
  sspinner: true,

  medata: [],
  mespinner: false,

  smdata: [],
  smspinner: false,

  dmData: [],

  staffAlAttData: [],
  stalspinner: false,

  aballData: [],
  abdata: [],
  abtotal: 1,
  abparams: {},

  erData: [],
  erspinner: false,

  exrData: [],
  expspinner: false,

  brData: [],
  brspinner: false,

  menuRef: [],
  menuSpinner: false,

  cwcallData: [],
  cwcdata: [],
  cwctotal: [],
  cwcparams: [],

  demoData: [],
  dspinner: [],
  dtokenExp: [],

  feeData: [],
  fspinner: false,

  incrCount: '',
  incrSpinner: false,
  
  billCount: "",
  bvspinner: false,

  swMentorData: [],
  swSpinner: false,
  swTokenExp: false,

  marksVerfyCount: "",
  mvSpinner: false,

  invSFData: {},
  invSFNetWorth: '',
  verPendListLength: '',
  invSFSpinner: false,
  
  shortFallList: [],
  shortfallData: [],
  totalSFSumData: [],
  shortFallTotal: '',

  verficationPendingList: [],
  verficationPendingData: [],
  totalVPSumData: [],
  verficationPendingTotal: '',

  invBranchWiseCount: [],
  invVerfPendCount: "",
  invPendSpinner: false,
  invPendTokenExp: false,

  bwbAllData: [],
  bwbData: [],
  bwbTotal: '',
  bwbParams: {},
  bwbSpinner: false,
  totalBracnWiseBalSumData: [],

  updAccIfscData: '',
  updAccIfscSpinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const initialTotalItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      // if (resp.bankType === "Cash") {
      //   resp.bank = Branches.find((find) => find.value === resp.bank)
      //     && Branches.find((find) => find.value === resp.bank).branchName

      // }
    });
    return resData;
  } else {
    return [];
  }
};

const initialTotalBillItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      // if (resp.bankType === 'Cash') {
      //   resp.bank = Branches.find(find => find.value === resp.bank) ? Branches.find(find => find.value === resp.bank).branchName : resp.bank
      // }
    });
    return resData;
  } else {
    return [];
  }
};

const initialTotalInvItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      // if (resp.bankType === 'Cash') {
      //   resp.bank = Branches.find(find => find.value === resp.bank) ? Branches.find(find => find.value === resp.bank).branchName : resp.bank
      // }
    });
    return resData;
  } else {
    return [];
  }
};

const initialTotalRecItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      // if (resp.bankType === 'Cash') {
      //   resp.bank = Branches.find(find => find.value === resp.bank) ? Branches.find(find => find.value === resp.bank).branchName : resp.bank
      // }
    });
    return resData;
  } else {
    return [];
  }
};

const initialaItem = (resData, feeData) => {
  if (resData) {
    const Branches = DefaultConfig().SubBranchList
    const AcademicYear = DefaultConfig().AcademicYear;
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      if (resp.catg === 'inv') {
        resp.category = 'Inventory'
      } else if (resp.catg === 'oth') {
        resp.category = 'Others'
      } else if (resp.catg === 'custInc') {
        resp.category = 'Customer Income'
      } else {
        resp.category = feeData.find((find) => find.value === resp.catg) && feeData.find((find) => find.value === resp.catg).label
      }
      resp.branchName = Branches.find((find) => find.compId === resp.branch) && Branches.find((find) => find.compId === resp.branch).branchName
      resp.acadamicYearValue = AcademicYear.find((find) => find.value === resp.acadamicYear) ? AcademicYear.find((find) => find.value === resp.acadamicYear).label : "";
    });
    return resData;
  } else {
    return [];
  }
}

const initialCgItem = (resData, feeData) => {
  if (resData) {
    const DateFormatMonth = DefaultConfig().DateFormatMonth;
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      resp.date = resp.date ? DateFormatMonth(resp.date) : ''
      if (resp.type === 'inv') {
        resp.category = 'Inventory'
      } else {
        resp.category = feeData.find((find) => find.value === resp.type) && feeData.find((find) => find.value === resp.type).label
      }
    });
    return resData;
  } else {
    return [];
  }
}

const initialBRItem = (resData) => {
  if (resData) {
    const Branches = DefaultConfig().SubBranchList
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      resp.branchName = Branches.find((find) => find.compId === resp.branch) && Branches.find((find) => find.compId === resp.branch).branchName
    });
    return resData;
  } else {
    return [];
  }
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DASHBOARD_DATA":
      return {
        ...state,
        balanceData: action.data,
        spinner: action.spinner,
      };
    case "DASHBOARD_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_TODAY_INCOME_DATA":
      return {
        ...state,
        incomeData: action.data,
        spinner: action.spinner,
      };
    case "INCOME_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_BILLS_DATA":
      return {
        ...state,
        billData: action.data,
        spinner: action.spinner,
      };
    case "BILLS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_RECEIVABLES_DATA":
      return {
        ...state,
        recData: action.data,
        spinner: action.spinner,
      };
    case "RECEIVABLE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_CURRENT_TOTAL_INCOME_DATA":
      return {
        ...state,
        curtotalIncData: action.data,
        ctispinner: action.spinner,
        totalIncData: action.data,
        tispinner: action.spinner,
      };
    case "TOTAL_CUR_INC_LOADING_SPINNER":
      return {
        ...state,
        ctispinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ALL_CAT_DATA":
      return {
        ...state,
        feeData: action.allData,
        fspinner: action.spinner
      };
    case "LOADING_CAT_SPINNER":
      return {
        ...state,
        fspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_TOTAL_INCOME_DATA":
      return {
        ...state,
        totalIncData: action.data,
        tispinner: action.spinner,
      };
    case "TOTAL_INC_LOADING_SPINNER":
      return {
        ...state,
        tispinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_REP_BR_DATA":
      return {
        ...state,
        allData: initialBRItem(action.allData),
        totalIncSumData: action.totalIncSumData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_BR_REP_DATA":
      return {
        ...state,
        data: action.data,
        totalIncSumData: action.totalIncSumData,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_REP_ACA_DATA":
      return {
        ...state,
        aallData: initialaItem(action.allData, action.feeData),
        totalIncASumData: action.totalIncASumData,
        adata: action.data,
        atotal: action.totalPages,
        aparams: action.params,
      };
    case "GET_ACA_REP_DATA":
      return {
        ...state,
        adata: action.data,
        totalIncASumData: action.totalIncASumData,
        atotal: action.totalPages,
        aparams: action.params,
      };
    case "GET_CLASS_MAP_DATA":
      return {
        ...state,
        callData: initialCgItem(action.allData, action.feeData),
        totalIncCSumData: action.totalIncCSumData,
        cdata: action.data,
        ctotal: action.totalPages,
        cparams: action.params,
        cmodel: true,
        selectTitle: action.selectTitle,
      };
    case "GET_CLS_REP_DATA":
      return {
        ...state,
        cdata: action.data,
        totalIncCSumData: action.totalIncCSumData,
        ctotal: action.totalPages,
        cparams: action.params,
      };
    case "CLOSE_CMODEL":
      return {
        ...state,
        cmodel: action.cmodel,
      };
    case "TB_LOADING_SPINNER":
      return {
        ...state,
        bspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_TOTAL_BALANCE":
      return {
        ...state,
        totalBankBalList: initialTotalItem(action.totalBankBalList),
        totalBalSumData: initialTotalItem(action.totalBankBalList),
        totalBalAmount: action.totalBalAmount,
        totalCashAmount : action.totCashAmount,
        bspinner: action.spinner,
      };
    case "GET_TOTAL_BALANCE_DATA":
      return {
        ...state,
        ballData: initialItem(action.allData),
        bdata: action.data,
        btotal: action.totalPages,
        bparams: action.params,
        bspinner: action.spinner,
      };
    case "GET_TOTAL_BRANCH_WIST_BALANCE_DATA":
        return {
          ...state,
          bwbAllData: initialItem(action.allData),
          totalBracnWiseBalSumData: action.totalBalSumData,
          bwbData: action.data,
          bwbTotal: action.totalPages,
          bwbParams: action.params,
          bwbSpinner: action.spinner,
      };
    case "GET_TOT_BRANCH_WISE_BAL_FIL_DATA":
      return {
        ...state,
        bwbData: action.data,
        bwbTotal: action.totalPages,
        totalBracnWiseBalSumData: action.totalBalSumData,
        bwbParams: action.params,
      };
    case "GET_TOT_BAL_FIL_DATA":
      return {
        ...state,
        bdata: action.data,
        btotal: action.totalPages,
        totalBalSumData: action.totalBalSumData,
        bparams: action.params,
      };
    case "TREC_LOADING_SPINNER":
      return {
        ...state,
        recspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_TOTAL_REC":
      return {
        ...state,
        totalRecList: initialTotalRecItem(action.totalRecList),
        totalRecSumData: initialTotalRecItem(action.totalRecList),
        totalRecsAmount: action.totalRecsAmount,
        totaldueAmount: action.totaldueAmount,
        termList: action.termList,
        selTermsList: action.selTermsList,
        recspinner: action.spinner,
        paidGData: action.paidData,
        concGData: action.concData,
        balGData: action.balData,
        clsListGData: action.clsListData,
      };
    case "GET_TOTAL_REC_DATA":
      return {
        ...state,
        trallData: initialItem(action.allData),
        trdata: action.data,
        trtotal: action.totalPages,
        trparams: action.params,
      };
    case "GET_TOT_REC_FIL_DATA":
      return {
        ...state,
        trdata: action.data,
        trtotal: action.totalPages,
        totalRecSumData: action.totalRecSumData,
        trparams: action.params,
      };

    case "TREC_CLS_WISE_LOADING_SPINNER":
      return {
        ...state,
        trcwspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_TOTAL_REC_CLS_WISE":
      return {
        ...state,
        totalRecCWList: initialTotalRecItem(action.totalRecList),
        totalRecCWSumData: initialTotalRecItem(action.totalRecList),
        totalRecsCWAmount: action.totalRecsAmount,
        totalCWdueAmount: action.totaldueAmount,
        termList: action.termList,
        selTermList: action.selTermList,
        trcwspinner: action.spinner,
        paidCWGData: action.paidData,
        concCWGData: action.concData,
        balCWGData: action.balData,
        clsListCWGData: action.clsListData,
      };
    case "GET_TOTAL_REC_CLS_WISE_DATA":
      return {
        ...state,
        trcwallData: initialItem(action.allData),
        trcwdata: action.data,
        trcwtotal: action.totalPages,
        trcwparams: action.params,
      };
    case "GET_TOT_REC_CLS_WISE_FIL_DATA":
      return {
        ...state,
        trcwdata: action.data,
        trcwtotal: action.totalPages,
        totalRecCWSumData: action.totalRecSumData,
        trcwparams: action.params,
      };

    case "TBILL_LOADING_SPINNER":
      return {
        ...state,
        blspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_TOTAL_BILL":
      return {
        ...state,
        totalBillList: initialTotalBillItem(action.totalBillList),
        totalBillSumData: initialTotalBillItem(action.totalBillList),
        totalBillAmount: action.totalBillAmount,
        outstandingBillAmount: action.outstandingBillAmount,
        blspinner: action.spinner,
      };
    case "GET_TOTAL_BILL_DATA":
      return {
        ...state,
        blallData: initialItem(action.allData),
        bldata: action.data,
        bltotal: action.totalPages,
        blparams: action.params,
        blspinner: action.spinner,
      };
    case "GET_TOT_BILL_FIL_DATA":
      return {
        ...state,
        bldata: action.data,
        bltotal: action.totalPages,
        totalBillSumData: action.totalBillSumData,
        blparams: action.params,
      };
    case "GET_STAFFS_ATT_DATA":
      return {
        ...state,
        staffAttData: action.data,
        stspinner: action.spinner,
      };
    case "GET_STAFF_ATT_LOADING_SPINNER":
      return {
        ...state,
        stspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STAFF_ATT_COUNT":
      return {
        ...state,
        stallData: initialItem(action.allData),
        stdata: action.data,
        sttotal: action.totalPages,
        stparams: action.params,
      };
    case "GET_STAFF_ATT_DATA_FILTER":
      return {
        ...state,
        stdata: action.data,
        sttotal: action.totalPages,
        stparams: action.params,
      };
    case "GET_STAFF_ATT_DTLS":
      return {
        ...state,
        dtallData: initialItem(action.allData),
        dtdata: action.data,
        dttotal: action.totalPages,
        dtparams: action.params,
      };
    case "GET_PERIOD_WISE_DTLS":
      return {
        ...state,
        dayWisePeriodData: initialItem(action.data),
      };
    case "GET_STAFF_ATT_DTLS_FILTER":
      return {
        ...state,
        dtdata: action.data,
        dttotal: action.totalPages,
        dtparams: action.params,
      };
    case "BUS_LOADING_SPINNER":
      return {
        ...state,
        busspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_TOTAL_BUS":
      return {
        ...state,
        budgetList: action.budgetList,
        investBudget : action.investBudget,
        totalBudget : action.totalBudget,
        busspinner: action.spinner,
      };

    case "ER_LOADING_SPINNER":
      return {
        ...state,
        erspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ER_DATA":
      return {
        // ...state,
        // erData: action.data,
        // erspinner: action.spinner,
        ...state,
        erAllData: initialItem(action.allData),
        erData: initialItem(action.data),
        erTotal: action.totalPages,
        params: action.params,
        erspinner: action.spinner,
      };
    case "GET_EXPENSE_REPORT_GETDATA":
      return {
        ...state,
        erData: action.data,
        erTotal: action.totalPages,
        params: action.params,
      };
    case "UPDATE_FIN_HEADS_SPINNER":
      return {
        ...state,
        erspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "UPDATE_FIN_HEAD_DATA":
      return {
        ...state,
        erData: action.data,
        erspinner: action.spinner,
      };

      case "UPDATE_EXP_YEAR_SPINNER":
      return {
        ...state,
        expYspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "UPDATE_EXP_YEAR_DATA":
      return {
        ...state,
        erAllData: initialItem(action.allData),
        erData: initialItem(action.data),
        erTotal: action.totalPages,
        expYspinner: action.spinner,
      };

      case "UPDATE_EXP_DESCR_LOADING_SPINNER":
      return {
        ...state,
        expspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "UPDATE_EXP_REMARK_IN_EXP_DATA":
      return {
        ...state,
        exrData: action.data,
        expspinner: action.spinner,
      };

    case "UPDATE_FIN_HEADS_IN_BILL_SPINNER":
      return {
        ...state,
        brspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "UPDATE_FIN_HEAD_IN_BILL_DATA":
      return {
        ...state,
        brData: action.data,
        brspinner: action.spinner,
      };

    case "GET_STU_ATT_LOADING_SPINNER":
      return {
        ...state,
        stuspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STU_ATT_DATA":
      return {
        ...state,
        stuAttData: action.data,
        stuspinner: action.spinner,
      };
    case "GET_STU_ATT_COUNT":
      return {
        ...state,
        stuallData: initialItem(action.allData),
        studata: action.data,
        stutotal: action.totalPages,
        stuparams: action.params,
      };
    case "GET_STU_ATT_DATA_FILTER":
      return {
        ...state,
        studata: action.data,
        stutotal: action.totalPages,
        stuparams: action.params,
      };
    case "GET_STU_ATT_BRANCH":
      return {
        ...state,
        stballData: initialItem(action.allData),
        stbdata: action.data,
        stbtotal: action.totalPages,
        stbparams: action.params,
      };
    case "GET_STU_ATT_BR_FILTER":
      return {
        ...state,
        stbdata: action.data,
        stbtotal: action.totalPages,
        stbparams: action.params,
      };
    case "GET_STU_ATT_DTLS":
      return {
        ...state,
        stdallData: initialItem(action.allData),
        stddata: action.data,
        stdtotal: action.totalPages,
        stdparams: action.params,
      };
    case "GET_STU_ATT_DTLS_FILTER":
      return {
        ...state,
        stddata: action.data,
        stdtotal: action.totalPages,
        stdparams: action.params,
      };
    case "GET_MEAP_LOADING_SPINNER":
      return {
        ...state,
        mespinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_MEAP_DATA":
      return {
        ...state,
        medata: action.data,
        mespinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "SAVE_MEAP_LOADING_SPINNER":
      return {
        ...state,
        mespinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "SAVE_MEAP_DATA":
      return {
        ...state,
        smdata: action.data,
        mespinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "REMOVE_ACCESS":
      return {
        ...state,
        medata: action.allData,
        mespinner: action.spinner,
      };
    case "REMOVE_ACCESS_LOADING_SPINNER":
      return {
        ...state,
        mespinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "TINVOICE_LOADING_SPINNER":
      return {
        ...state,
        invspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_TOTAL_INVOICE":
      return {
        ...state,
        catgWiseInvDtls: initialTotalInvItem(action.catgWiseInvDtls),
        catgWiseList: initialTotalInvItem(action.catgWiseList),
        totalCWSumData: initialTotalInvItem(action.catgWiseInvDtls),
        totalInvList: initialTotalInvItem(action.totalInvList),
        totalInvSumData: initialTotalInvItem(action.totalInvList),
        totalInvAmount: action.totalInvAmount,
        totalInvDueAmount: action.totalInvdueAmount,
        invspinner: action.spinner,
      };
      case "ID_STATUS_LOADING_SPINNER":
      return {
        ...state,
        invspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "ID_STATUS":
      return {
        ...state,
        idData: action.data,
        invspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_TOTAL_INVOICE_DATA":
      return {
        ...state,
        invallData: initialItem(action.allData),
        invdata: action.data,
        invtotal: action.totalPages,
        invparams: action.params,
        invspinner: action.spinner,
      };
    case "GET_TOT_INVOICE_FIL_DATA":
      return {
        ...state,
        invdata: action.data,
        invtotal: action.totalPages,
        totalInvSumData: action.totalInvSumData,
        invparams: action.params,
      };
    case "GET_CATG_WISE_INVOICE_DATA":
      return {
        ...state,
        cwallData: initialItem(action.allData),
        cwdata: action.data,
        cwtotal: action.totalPages,
        cwparams: action.params,
        cwspinner: action.spinner,
      };
    case "GET_CATG_WISE_INVOICE_FIL_DATA":
      return {
        ...state,
        cwdata: action.data,
        cwtotal: action.totalPages,
        totalCWSumData: action.totalCWSumData,
        cwparams: action.params,
      };
    case "STU_ATT_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STU_UPDATE_DAY_DATA":
      return {
        ...state,
        sspinner: action.spinner,
        stdallData: initialItem(action.allData),
        stddata: action.stddata,
        stdtotal: action.totalPages,
      };
    case "GET_STAFF_ATT_AL_DATA":
      return {
        ...state,
        staffAlAttData: action.data,
        stalspinner: action.spinner,
      };
    case "GET_STAFF_AL_LOADING_SPINNER":
      return {
        ...state,
        stalspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STAFF_ATT_AB_DTLS":
      return {
        ...state,
        aballData: initialItem(action.allData),
        abdata: action.data,
        abtotal: action.totalPages,
        abparams: action.params,
      };
    case "GET_STAFF_ATT_AB_DTLS_FILTER":
      return {
        ...state,
        abdata: action.data,
        abtotal: action.totalPages,
        abparams: action.params,
      };
    case "UPDATE_MENU_REF":
      return {
        ...state,
        menuRef: action.data,
        menuSpinner: action.spinner,
      };
    case "UPDATE_MENU_REF_LOADING_SPINNER":
      return {
        ...state,
        menuSpinner: action.spinner,
      };

    case "GET_CATG_WISE_DATA":
      return {
        ...state,
        cwcallData: initialItem(action.allData),
        cwcdata: initialItem(action.data),
        cwctotal: action.totalPages,
        cwcparams: action.params,
      };
    case "GET_CATG_WISE_FIL_DATA":
      return {
        ...state,
        cwcdata: action.data,
        cwctotal: action.totalPages,
        cwcparams: action.params,
      };

    case "GET_ALL_DEMO_USERS_DATA":
      return {
        ...state,
        demoData: action.allData,
        // data: action.data,
        //total: action.totalPages,
        // params: action.params,
        spinner: action.spinner,
      };
    case "DEMO_USERS_LOADING_SPINNER":
      return {
        ...state,
        dspinner: action.spinner,
        dtokenExp: action.tokenExp,
      };

      case "GET_INCR_PENDING_COUNT":
      return {
        ...state,
        incrCount: action.count,
        incrSpinner: action.spinner,
      };
    case "GET_INCR_PENDING_COUNT_LOADING_SPINNER":
      return {
        ...state,
        incrSpinner: action.spinner,
        incrTokenExp: action.tokenExp,
      };
    case "GET_VERIFY_BILL_ALLDATA":
      return {
        ...state,
        billCount: action.allData,
        bvspinner: action.spinner,
      };
    case "BILL_VERIFICATION_LOADING_SPINNER" :
      return {
        ...state,
        bvspinner : action.spinner,
        bvTokenExp : action.tokenExp,
      }
    case "GET_SW_MENTOR_DTLS":
      return {
        ...state,
        swMentorData: action.allData,
        swSpinner: action.spinner,
      };
    case "SW_MENTOR_DTLS_LOADING_SPINNER" :
      return {
        ...state,
        swSpinner : action.spinner,
        swTokenExp : action.tokenExp,
      }
      
    case "MARKS_VERIFICATION_COUNT_LOADING_SPINNER" :
      return {
        ...state,
        mvSpinner : action.spinner,
        mvTokenExp : action.tokenExp,
      }
    case "GET_MARKS_VERFY_COUNT":
      return {
        ...state,
        marksVerfyCount: action.allData,
        mvSpinner: action.spinner,
      };
    case "GET_INV_SHORTFALL_WORTH_LOADING_SPINNER" :
      return {
        ...state,
        invSFSpinner : action.spinner,
        tokenExp : action.tokenExp,
      }
    case "GET_INV_SHORTFALL_WORTH":
      return {
        ...state,
        invSFData: action.allData,
        invSFNetWorth: action.netWorth,
        verPendListLength: action.verPendListLength,
        invSFSpinner: action.spinner,
      };
    case "GET_INV_SHORTFALL_LIST":
      return {
        ...state,
        shortFallList: initialItem(action.allData),
        shortfallData: initialItem(action.data),
        totalSFSumData: action.totalSFSumData,
        shortFallTotal: action.totalPages,
        params: action.params,
      };
    case "GET_SHORTFALL_FILTER_DATA":
      return {
        ...state,
        shortfallData: action.data,
        totalSFSumData: action.totalSFSumData,
        shortFallTotal: action.totalPages,
        params: action.params,
      };
    case "GET_INV_VERIFICATION_PEND_LIST":
      return {
        ...state,
        verficationPendingList: initialItem(action.allData),
        verficationPendingData: initialItem(action.data),
        totalVPSumData: action.totalSFSumData,
        verficationPendingTotal: action.totalPages,
        params: action.params,
      };
    case "GET_INV_VERIFICATION_PEND_FILTER_DATA":
      return {
        ...state,
        verficationPendingData: action.data,
        totalVPSumData: action.totalSFSumData,
        verficationPendingTotal: action.totalPages,
        params: action.params,
      };
    case "INVENTORY_VERIFICATION_PENDCOUNT_LOADING_SPINNER" :
      return {
        ...state,
        invPendSpinner : action.spinner,
        invPendTokenExp : action.tokenExp,
      }
    case "GET_INV_VERFY_PEND_COUNT":
      return {
        ...state,
        invBranchWiseCount: action.allData,
        invVerfPendCount: action.verfPendCount,
        invPendSpinner: action.spinner,
      };
    case "UPDATE_ACC_IFSC_LOADING_SPINNER" :
      return {
        ...state,
        updAccIfscSpinner : action.spinner,
      }
    case "UPDATE_ACC_IFSC":
      return {
        ...state,
        dtallData: initialItem(action.allData),
        updAccIfscSpinner: action.spinner,
      };
    default:
      return { ...state };
  }
};
export default DashboardReducer;
